import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  const settingApi = routesApi.company.taxflowSetting;
  return {
    getAssignedCountries(data: dataReqI) {
      return api.request({
        method: settingApi.getAssignedCountries.method,
        route: settingApi.getAssignedCountries.path,
        params: data.params,
      });
    },
    updateAssignedCountries(data: dataReqI){
      return api.request({
        method: settingApi.updateAssignCountry.method,
        route: settingApi.updateAssignCountry.path,
        params: data.params,
        data: data.data
      });
    },
  };
};
