import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Tab, Divider } from 'semantic-ui-react';
import SelectedCountries from './SelectedCountries';

const Index = ({
  company,
  user
}) => {

  return (
    <Tab.Pane>

    <Divider hidden />
    <Tab
      menu={{ fluid: true, vertical: true, tabular: true }}
      panes={[
        {
          menuItem: 'Countries',
          render: () => (
            <SelectedCountries
              company={company}
              subscribedServices={company.Subscriptions}
            />
          ),
        },
      ]}
    />
  
  </Tab.Pane>
  );
};

const mapStateToProps = (state) => {};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
