import React, {useState, useEffect} from 'react';
import { actions } from 'store';

import { Field } from 'formik';
import InputFlexCustom from 'components/QuickFixes/DeliveryForm/InputFlexCustom';
//import { Dropdown } from 'semantic-ui-react';
import { Spacer, Wrapper } from 'components/core';
import Dropdown from 'components/Dropdown';
import { Grid } from 'semantic-ui-react';

const Index = ({
  dispatch, 
  companyId, 
  errors, 
  touched, 
  names, 
  noAddress, 
  userAdminOrOp, 
  setFieldTouched, 
  company, 
  deliveryId, 
  valuesVat, 
  setFieldValue, 
  disabled, 
  isSuperOperatorCompany}: any) => {

  const [sellerVats, setSellerVats]: any = useState(null)

  useEffect(() => {
    if(valuesVat){
      setFieldValue(names.vat, valuesVat);
    }
  }, [valuesVat, setFieldValue, names.vat]);

  useEffect(() => {
    if(company && deliveryId){
      company.Vats && setSellerVats(company.Vats.map((e, index) => {
        return {
          key: index,
          value: e.id,
          text: e.vatNumber,
        }
      }))
    }
  }, [company, deliveryId])

  const changeVat = (e) => {
    if(e !== undefined){
      dispatch(actions.quickfixes.changeVatNumber({
        params: {
          companyId: companyId,
          deliveryId: deliveryId,
          vat: e
        }
      }))
    }
  }

 
  return (
    <>
      <Field
        label='Company'
        name={names.name}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        widthLabel='5'
        widthInput='11'
      />
      <Field
        label='Country'
        name={names.addressCountry}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        widthLabel='5'
        widthInput='11'
      />
      <Field
        label='City'
        name={names.addressCity}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        widthLabel='5'
        widthInput='11'
      />
      {!noAddress && (
        <Field
          label='Address'
          name={names.address1}
          type='text'
          component={InputFlexCustom}
          disabled={disabled}
          meta={{ errors, touched }}
          margin='5px'
          columns='2'
          widthLabel='5'
          widthInput='11'
        />
      )}
      <Field
        label='Email'
        name={names.email}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        widthLabel='5'
        widthInput='11'
      />
      <Field
        label='Phone'
        name={names.phone}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        widthLabel='5'
        widthInput='11'
      />
      <Field
        label='VAT'
        name={names.vat}
        type='text'
        placeholder={valuesVat}
        value={valuesVat}
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        widthLabel='5'
        widthInput='11'
      />
    <Spacer />
      {(userAdminOrOp || companyId === Number(isSuperOperatorCompany)) && (
      <Wrapper>
      <Grid>
        <Grid.Row columns={2}>
            <Grid.Column width={6}>
              <label>VAT Number</label>
            </Grid.Column>
            <Grid.Column width={10} style={{'padding': '0px 17px 0px 12px'}}>
              <Field
                name={'seller.vatId'}
                placeholder='Change VAT number'
                component={Dropdown}
                options={sellerVats}
                meta={{errors, touched, setFieldValue, setFieldTouched}}
                onChange={changeVat}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Wrapper>
        )}
            
    </>
  );
};



export default Index;

