let actionTypes = {

  GET_ASSIGNED_COUNTRIES: "MY_ASD.SETTINGS.TAXFLOW.GET_ASSIGNED_COUNTRIES",
  GET_ASSIGNED_COUNTRIES_LOADING: "MY_ASD.SETTINGS.TAXFLOW.GET_ASSIGNED_COUNTRIES_LOADING",
  GET_ASSIGNED_COUNTRIES_SUCCESS: "MY_ASD.SETTINGS.TAXFLOW.GET_ASSIGNED_COUNTRIES_SUCCESS",
  GET_ASSIGNED_COUNTRIES_ERROR: "MY_ASD.SETTINGS.TAXFLOW.GET_ASSIGNED_COUNTRIES_ERROR",

  UPDATE_ASSIGNED_COUNTRIES: "MY_ASD.SETTINGS.TAXFLOW.UPDATE_ASSIGNED_COUNTRIES",
  UPDATE_ASSIGNED_COUNTRIES_LOADING: "MY_ASD.SETTINGS.TAXFLOW.UPDATE_ASSIGNED_COUNTRIES_LOADING",
  UPDATE_ASSIGNED_COUNTRIES_SUCCESS: "MY_ASD.SETTINGS.TAXFLOW.UPDATE_ASSIGNED_COUNTRIES_SUCCESS",
  UPDATE_ASSIGNED_COUNTRIES_ERROR: "MY_ASD.SETTINGS.TAXFLOW.UPDATE_ASSIGNED_COUNTRIES_ERROR",

  RESET: "MY_ASD.SETTINGS.TAXFLOW.RES",
  REFRESH_ERROR: "MY_ASD.SETTINGS.TAXFLOW.REFRESH_ERROR"
};

export default actionTypes;
