import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors, actions } from 'store';
import { Form, Icon } from 'semantic-ui-react';
import { wWitdth } from './styles';

import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import { FiscalPeriod } from 'components/Form';
import { filtersSchema } from 'interfaces/formSchema/customsClerk';
import { Input } from 'components/Form';
import Dropdown from 'components/Dropdown';

const SearchFilters = ({
  dispatch,
  applyFilters,
  loading,
  resetFilters,
  isVisible,
  companies,
}: any) => {
  const [companiesList, setcompaniesList] = useState([]);

  useEffect(() => {
    if (isVisible) {
      dispatch(actions.customsDeclaration.getCompanySubscribed());
    } else {
      dispatch(
        actions.customsDeclaration.reset(['companySubscribed', 'error'])
      );
    }
  }, [dispatch, isVisible]);

  useEffect(() => {
    if (isVisible && companies) {
      setcompaniesList(
        companies.map((company, index) => {
          return {
            key: index,
            value: company.id,
            flag: company.country.toLowerCase(),
            text: company.name,
          };
        })
      );
    }
  }, [companies, isVisible]);

  const paymentMethods = [
    {
      key: 1,
      value: 'creditCard',
      text: 'Credit card',
    },
    {
      key: 2,
      value: 'asdPayzen',
      text: 'ASD Payzen',
    },
    {
      key: 3,
      value: 'cash',
      text: 'Cash',
    },
    {
      key: 4,
      value: 'provision',
      text: 'Provision',
    },
  ];

  return (
    <Segment
      {...(!isVisible && { display: 'none' })}
      margin='1rem 0 0 0'
      width='100%'
    >
      <Formik
        initialValues={{
          declarationId: '',
          company: '',
          startDate: '',
          endDate: '',
          paidBy: '',
          minimalAmount: '',
          maximalAmount: '',
          licensePlate: '',
        }}
        onSubmit={applyFilters}
        validationSchema={filtersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
              <Wrapper margin='0.5rem'>
                <Field
                  placeholder='From'
                  name='startDate'
                  component={FiscalPeriod}
                  meta={{ errors, touched, setFieldValue, setFieldTouched }}
                />
              </Wrapper>
              <Wrapper margin='0.5rem'>
                <Field
                  placeholder='To'
                  name='endDate'
                  component={FiscalPeriod}
                  meta={{ errors, touched, setFieldValue, setFieldTouched }}
                />
              </Wrapper>
            </Wrapper>
            <Wrapper display='flex' justifyContent='center'>
            <Wrapper 
                display='flex' 
                margin='0.5rem 0' 
                justifyContent='center' 
                flexFlow='row wrap' 
                flexDirection='wrap'
                >
                <Wrapper margin='0.5rem' width={wWitdth}>
                  <Field
                    placeholder='Declaration Id'
                    name='declarationId'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width={wWitdth}>
                  <Field
                    placeholder='Company'
                    name='company'
                    options={companiesList}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>

                <Wrapper margin='0.5rem' width={wWitdth}>
                  <Field
                    placeholder='License Plate'
                    name='licensePlate'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    margin='20px'
                  />
                </Wrapper>
              </Wrapper>
        
              <Wrapper 
                display='flex' 
                margin='0.5rem 0' 
                justifyContent='center' 
                flexFlow='row wrap' 
                flexDirection='wrap'
                >
                <Wrapper margin='0.5rem' width={wWitdth}>
                  <Field
                    placeholder='Payment method'
                    name='paidBy'
                    options={paymentMethods}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width={wWitdth}>
                  <Field
                    placeholder='Minimal amount'
                    name='minimalAmount'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width={wWitdth}>
                  <Field
                    placeholder='Maximal amount'
                    name='maximalAmount'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    margin='20px'
                  />
                </Wrapper>
            </Wrapper>
            </Wrapper>

            <Wrapper display='flex' margin='1rem' justifyContent='center'>
              <Button
                disabled={loading}
                {...(loading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Button
                type='button'
                content='Reset'
                color='red'
                onClick={() => {
                  resetFilters();
                  resetForm();
                }}
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.customsDeclaration.companySubscribedSelector(state),
    loading: selectors.customsDeclaration.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(SearchFilters);
