import { routesApi } from 'config/api';
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    sendNotificationQueue(data: dataReqI){
      const queueApi = routesApi.customs.declaration.queueSendNotif;
      return api.request({
        method: queueApi.method,
        route: queueApi.path,
        params: data.params,
      });
    },
    deleteConsigmentData(data: dataReqI) {
      const consognmentFile = routesApi.customs.declaration.deleteConsignment;
      return api.request({
        method: consognmentFile.method,
        route: consognmentFile.path,
        params: data.params,
      });
    },
    createTransit(data: dataReqI) {
      const createTransitFile = routesApi.customs.declaration.createTransit;
      const form: any = new FormData();
      form.set('type[]', data.data.type);
      form.set('customsOffice', data.data.customsOffice);
      form.set('truckLicensePlate', data.data.truckLicensePlate);
      form.set('trailerLicensePlate', data.data.trailerLicensePlate);
      form.set('fortyTwoRegime', data.data.fortyTwoRegime);
      form.set('indRepresentation', data.data.indRepresentation);
      form.set('truckOrigin', data.data.truckOrigin);
      form.set('trailerOrigin', data.data.trailerOrigin);
      form.set('clientReference', data.data.clientReference);
      form.set('departure', data.data.departure);
      form.set('arrival', data.data.arrival);
      form.set('startDate', data.data.startDate);
      form.set('endDate', data.data.endDate);
      form.set('estimatedTimeDeparture', data.data.estimatedTimeDeparture);
      form.append('transporter[]', JSON.stringify(data.data.transporter));
      form.set('CompanyDriver_Id', data.data.CompanyDriver_Id); 
      form.set('transporterPhoneNumber', data.data.transporterPhoneNumber);
      form.set('purchaseNumber', data.data.purchaseNumber);
      form.set('preTaxInvoice', data.data.preTaxInvoice);
      form.set('customsDutyRate', data.data.customsDutyRate);
      form.set('transitVatRate', data.data.transitVatRate); 
      form.set('accisesTaxAmount', data.data.accisesTaxAmount); 
      data.data.documents.forEach((document) => {
        form.append('documents', document.file);
        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: document.id,
            reference: document.reference,
          })
        );
      });

      return api.request({
        method: createTransitFile.method,
        route: createTransitFile.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },

   
    getTransitRisk(data: dataReqI){
      const getTransitRiskFile = routesApi.customs.declaration.transitRisk;
      return api.request({
        method: getTransitRiskFile.method,
        route: getTransitRiskFile.path,
        params: data.params,
        data: data.data,
      });
    },

    updateTransit(data: dataReqI) {
      const updateTransitFile = routesApi.customs.declaration.updateTransit;
      return api.request({
        method: updateTransitFile.method,
        route: updateTransitFile.path,
        params: data.params,
        data: data.data,
      });
    },

    updateAccises(data: dataReqI) {
      const updateAccisesFile = routesApi.customs.declaration.updateAccises;
      return api.request({
        method: updateAccisesFile.method,
        route: updateAccisesFile.path,
        params: data.params,
        data: data.data,
      });
    },

    createAccises(data: dataReqI) {
      const createAccisesFile = routesApi.customs.declaration.createAccises;
      const form: any = new FormData();
      form.set('type[]', data.data.type);
      form.set('customsOffice', data.data.customsOffice);
      form.set('truckLicensePlate', data.data.truckLicensePlate);
      form.set('trailerLicensePlate', data.data.trailerLicensePlate);
      form.set('fortyTwoRegime', data.data.fortyTwoRegime);
      form.set('indRepresentation', data.data.indRepresentation);
      form.set('truckOrigin', data.data.truckOrigin);
      form.set('trailerOrigin', data.data.trailerOrigin);
      form.set('clientReference', data.data.clientReference);
      form.set('departure', data.data.departure);
      form.set('arrival', data.data.arrival);
      form.set('startDate', data.data.startDate);
      form.set('endDate', data.data.endDate);
      data.data.documents.forEach((document) => {
        form.append('documents', document.file);
        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: document.id,
            reference: document.reference,
          })
        );
      });

      return api.request({
        method: createAccisesFile.method,
        route: createAccisesFile.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    addConsigmentData(data: dataReqI) {
      const consognmentFile = routesApi.customs.declaration.addConsignment;
      return api.request({
        method: consognmentFile.method,
        route: consognmentFile.path,
        params: data.params,
        data: data.data,
      });
    },
    updateConsigmentData(data: dataReqI) {
      const consognmentFile = routesApi.customs.declaration.updateConsignment;
      return api.request({
        method: consognmentFile.method,
        route: consognmentFile.path,
        params: data.params,
        data: data.data,
      });
    },
    getConex(data: dataReqI){
      const getConex = routesApi.customs.declaration.getConex;
      return api.request({
        method: getConex.method,
        route: getConex.path,
        params: data.params,
      });
    },

    getTransitRates(data: dataReqI){
      const getTransitRatesApi = routesApi.customs.declaration.transitRates;
      return api.request({
        method: getTransitRatesApi.method,
        route: getTransitRatesApi.path,
        params: data.params,
      });
    },


    checkConexDelivery(data: dataReqI){
      const checkConex = routesApi.customs.declaration.checkConexDelivery;
      return api.request({
        method: checkConex.method,
        route: checkConex.path,
        params: data.params,
      });
    },

    addLines(data: dataReqI){
      const addLines = routesApi.customs.declaration.addLines;
      return api.request({
        method: addLines.method,
        route: addLines.path,
        params: data.params,
        data: data.data,
      });
    },

    updateLines(data: dataReqI){
      const updateLines = routesApi.customs.declaration.updateLines;
      return api.request({
        method: updateLines.method,
        route: updateLines.path,
        params: data.params,
        data: data.data,
      });
    },
    deleteLines(data: dataReqI){
      const deleteLines = routesApi.customs.declaration.deleteLines;
      return api.request({
        method: deleteLines.method,
        route: deleteLines.path,
        params: data.params,
      });
    },
    getAllLines(data: dataReqI) {
      const allLines = routesApi.customs.declaration.getAllLines;
      return api.request({
        method: allLines.method,
        route: allLines.path,
        params: data.params,
      });
    },
    sendNoticeMail(data: dataReqI) {
      const sendNoticeMail = routesApi.customs.declaration.sendNoticeMail;
      return api.request({
        method: sendNoticeMail.method,
        route: sendNoticeMail.path,
        params: data.params,
      });
    },
    reloadLogisticom(data: dataReqI) {
      const reloadLogisticom = routesApi.customs.declaration.reloadLogisticom;
      return api.request({
        method: reloadLogisticom.method,
        route: reloadLogisticom.path,
        params: data.params,
      });
    },
    getAllChed(data: dataReqI) {
      const getAllChedApi = routesApi.customs.declaration.getAllChed;
      return api.request({
        method: getAllChedApi.method,
        route: getAllChedApi.path,
        params: data.params,
      });
    },
    getOneChed(data: dataReqI) {
      const getOneChed = routesApi.customs.declaration.getOneChed;
      return api.request({
        method: getOneChed.method,
        route: getOneChed.path,
        params: data.params,
        responseType: 'blob',
      });
    },

    addChed(data: dataReqI) {
      const addChed = routesApi.customs.declaration.addChed;
      const form: any = new FormData();

      form.set('type', data.data.type.id);
      form.set('number', data.data.number);
      form.set('productNames', data.data.productNames);
      form.set('productType', data.data.productType);
      form.set('weight', data.data.weight);
      form.set('countLines', data.data.countLines);
      form.set('state', data.data.state);
      data.data.taxAmount && form.set('taxAmount', data.data.taxAmount);
      data.data.phyto && form.set('phyto', data.data.phyto);
      data.data.veto && form.set('veto', data.data.veto);
      data.data.coi && form.set('coi', data.data.coi);
      data.data.file && form.append('file', data.data.file);

      return api.request({
        method: addChed.method,
        route: addChed.path,
        params: data.params,
        data: form,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },

    removeChed(data: dataReqI) {
      const removeChed = routesApi.customs.declaration.removeChed;
      return api.request({
        method: removeChed.method,
        route: removeChed.path,
        params: data.params,
      });
    },

    convertFile(data: dataReqI) {
      const convertFile = routesApi.customs.declaration.convertFile;
      return api.request({
        method: convertFile.method,
        route: convertFile.path,
        data: data.data,
        responseType: 'blob',
      });
    },

    updateChedData(data: dataReqI) {
      const updateChedData = routesApi.customs.declaration.updateChedData;

      const form: any = new FormData();

      form.set('type', data.data.type.id);
      form.set('number', data.data.number);
      form.set('productNames', data.data.productNames);
      form.set('productType', data.data.productType);
      form.set('weight', data.data.weight);
      form.set('countLines', data.data.countLines);
      form.set('state', data.data.state);
      data.data.taxAmount && form.set('taxAmount', data.data.taxAmount);
      data.data.phyto && form.set('phyto', data.data.phyto);
      data.data.veto && form.set('veto', data.data.veto);
      data.data.coi && form.set('coi', data.data.coi);
      data.data.replacingNumber && form.set('replacingNumber', data.data.replacingNumber);
      data.data.file && form.append('file', data.data.file);

      return api.request({
        method: updateChedData.method,
        route: updateChedData.path,
        params: data.params,
        data: form,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },

    readChat(data: dataReqI) {
      const readChatApi = routesApi.customs.declaration.readChat;
      return api.request({
        method: readChatApi.method,
        route: readChatApi.path,
        params: data.params,
      });
    },

    getDeclarationsInvoice(data: dataReqI) {
      const getDeclarationsInvoiceApi =
        routesApi.customs.declaration.getDeclarationInvoice;
      return api.request({
        method: getDeclarationsInvoiceApi.method,
        route: getDeclarationsInvoiceApi.path,
        query: data.query,
      });
    },

    getDeclarationsInvoiceByFilters(data: dataReqI) {
      const getDeclarationsInvoiceApi =
        routesApi.customs.declaration.getDeclarationInvoiceByFilters;
      return api.request({
        method: getDeclarationsInvoiceApi.method,
        route: getDeclarationsInvoiceApi.path,
        query: data.query,
        data: data.data,
      });
    },

    updateDeclarationsInvoice(data: dataReqI) {
      const updateDeclarationsInvoice =
        routesApi.customs.declaration.updateDeclarationInvoice;
      return api.request({
        method: updateDeclarationsInvoice.method,
        route: updateDeclarationsInvoice.path,
        params: data.params,
      });
    },

    getCompanySubscribed() {
      const companySubscribedApi =
        routesApi.customs.declaration.companySubscribed;
      return api.request({
        method: companySubscribedApi.method,
        route: companySubscribedApi.path,
      });
    },

    subscribe(data: dataReqI) {
      const subscribeApi = routesApi.customs.declaration.subscribe;
      return api.request({
        method: subscribeApi.method,
        route: subscribeApi.path,
        params: data.params,
      });
    },

    unsubscribe(data: dataReqI) {
      const unsubscribeApi = routesApi.customs.declaration.unsubscribe;
      return api.request({
        method: unsubscribeApi.method,
        route: unsubscribeApi.path,
        params: data.params,
      });
    },

    addIntranetReference(data: dataReqI) {
      const addIntranetReferenceApi =
        routesApi.customs.declaration.addIntranetReference;
      return api.request({
        method: addIntranetReferenceApi.method,
        route: addIntranetReferenceApi.path,
        params: data.params,
        data: data.data,
      });
    },

    updateDeclaration(data: dataReqI) {
      const updateDeclarationApi =
        routesApi.customs.declaration.updateDeclaration;
      return api.request({
        method: updateDeclarationApi.method,
        route: updateDeclarationApi.path,
        params: data.params,
        data: data.data,
      });
    },

    updateStep(data: dataReqI) {
      const updateStepApi = routesApi.customs.declaration.updateStep;
      return api.request({
        method: updateStepApi.method,
        route: updateStepApi.path,
        params: data.params,
        data: data.data,
      });
    },

    cancelDeclaration(data: dataReqI) {
      const cancelDeclarationApi =
        routesApi.customs.declaration.cancelDeclaration;
      return api.request({
        method: cancelDeclarationApi.method,
        route: cancelDeclarationApi.path,
        params: data.params,
        data: data.data,
      });
    },

    addStep(data: dataReqI) {
      const addStepApi = routesApi.customs.declaration.addStep;
      return api.request({
        method: addStepApi.method,
        route: addStepApi.path,
        params: data.params,
        data: data.data,
      });
    },

    getAllCustomsOffice() {
      const getAllCustomsOfficeApi =
        routesApi.customs.declaration.getAllCustomsOffice;
      return api.request({
        method: getAllCustomsOfficeApi.method,
        route: getAllCustomsOfficeApi.path,
      });
    },

    getProcessingDeclarations(data: dataReqI) {
      const getProcessingDeclarationsApi =
        routesApi.customs.declaration.getProcessingDeclarations;
      return api.request({
        method: getProcessingDeclarationsApi.method,
        route: getProcessingDeclarationsApi.path,
        query: data.query,
      });
    },

    getProcessingDeclarationsByFilters(data: dataReqI) {
      const getProcessingDeclarationsApi =
        routesApi.customs.declaration.getProcessingDeclarationsByFilters;
      return api.request({
        method: getProcessingDeclarationsApi.method,
        route: getProcessingDeclarationsApi.path,
        query: data.query,
        data: data.data,
      });
    },
    getAll(data: dataReqI) {
      const getAllApi = routesApi.customs.declaration.getAll;
      return api.request({
        method: getAllApi.method,
        route: getAllApi.path,
        params: data.params,
        query: data.query,
      });
    },
    getAllHosted(data: dataReqI) {
      const getAllHostedApi = routesApi.customs.declaration.getAllHosted;
      return api.request({
        method: getAllHostedApi.method,
        route: getAllHostedApi.path,
        params: data.params,
        query: data.query,
      });
    },
    getOneHosted(data: dataReqI) {
      const getOneHostedApi = routesApi.customs.declaration.getOneHosted;
      return api.request({
        method: getOneHostedApi.method,
        route: getOneHostedApi.path,
        params: data.params,
        query: data.query,
      });
    },
    getOneByReference(data: dataReqI) {
      const getOneByRefApi = routesApi.customs.declaration.getOneByReference;
      return api.request({
        method: getOneByRefApi.method,
        route: getOneByRefApi.path,
        params: data.params,
      });
    },
    getAllCategoriesDocuments(data: dataReqI) {
      const getAllCategoriesDocumentsApi =
        routesApi.customs.declaration.getAllCategoriesDocuments;
      return api.request({
        method: getAllCategoriesDocumentsApi.method,
        route: getAllCategoriesDocumentsApi.path,
        query: data.query
      });
    },
    getOne(data: dataReqI) {
      const getOneApi = routesApi.customs.declaration.getOne;
      return api.request({
        method: getOneApi.method,
        route: getOneApi.path,
        params: data.params,
      });
    },
    getParking() {
      const getParkingApi = routesApi.customs.declaration.getParking;
      return api.request({
        method: getParkingApi.method,
        route: getParkingApi.path,
      });
    },
  
    downloadDocuments(data: dataReqI) {
      const downloadDocumentsApi =
        routesApi.customs.declaration.downloadAllDocuments;
      return api.request({
        method: downloadDocumentsApi.method,
        route: downloadDocumentsApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    downloadCheds(data: dataReqI) {
      const downloadChedsApi =
        routesApi.customs.declaration.downloadCheds;
      return api.request({
        method: downloadChedsApi.method,
        route: downloadChedsApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    extractDocuments(data: dataReqI) {
      const extractDocumentsApi =
        routesApi.customs.declaration.extractDocuments;
      return api.request({
        method: extractDocumentsApi.method,
        route: extractDocumentsApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    getAllTraces(data: dataReqI) {
      const getAllTracesApi =
        routesApi.customs.declaration.getAllTraces;
      return api.request({
        method: getAllTracesApi.method,
        route: getAllTracesApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    generateBaeExtract(data: dataReqI) {
      const genBaeExtactsApi = routesApi.customs.declaration.extractDauBae;
      return api.request({
        method: genBaeExtactsApi.method,
        route: genBaeExtactsApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    addPartnerComment(data: dataReqI){
      const addComPartnerApi = routesApi.customs.declaration.addPartnerComment;
      return api.request({
        method: addComPartnerApi.method,
        route: addComPartnerApi.path,
        params: data.params,
        data: data.data,    
      });
    },
    getPartnerComments(data: dataReqI){
      const getComPartnerApi = routesApi.customs.declaration.getPartnerComments;
      return api.request({
        method: getComPartnerApi.method,
        route: getComPartnerApi.path,
        params: data.params,
        data: data.data,
       
      });
    },
    declare(data: dataReqI) {
      const declareApi = routesApi.customs.declaration.declare;
      const form: any = new FormData();

      data.data.type.forEach((t) => form.append('type[]', t.toString()));
      form.set('departure', data.data.departure);
      form.set('arrival', data.data.arrival);
      form.set('fileDeclaDeparture', data.data.fileDeclaDeparture);
      form.set('fileDeclaArrival', data.data.fileDeclaArrival);
      form.set('fileGmrDocument', data.data.fileGmrDocument);
      form.set('truckLicensePlate', data.data.truckLicensePlate);
      form.set('trailerLicensePlate', data.data.trailerLicensePlate);
      form.set('truckOrigin', data.data.truckOrigin);
      form.set('trailerOrigin', data.data.trailerOrigin);
      form.set('customsOffice', data.data.customsOffice);
      form.set('meanTimeOffset', data.data.meanTimeOffset); 
      form.set('CompanyDriver_Id', data.data.CompanyDriver_Id); 
      typeof data.data.customsTrade === 'object' ? 
      data.data.customsTrade.forEach((c) =>  {
        form.append('customsTrade[]', c.toString());
      }) : 
      form.set('customsTrade', data.data.customsTrade);
      form.set('fortyTwoRegime', data.data.fortyTwoRegime); 
      form.set('indRepresentation', data.data.indRepresentation); 
      form.set('purchaseNumber', data.data.purchaseNumber); 
      form.set('estimatedDateTimeArrival', data.data.estimatedDateTimeArrival);
      form.set('transporterPhoneNumber', data.data.transporterPhoneNumber);
      form.append('transporter[]',  
        JSON.stringify({
          id: data.data.transporter.id,
          name: data.data.transporter.name,
        })
      );
      form.set('clientReference', data.data.clientReference);
      form.set('crossingStatus', data.data.crossingStatus);
      data.data.permissions && data.data.permissions.forEach((partner) => {
        form.append('partners[]',  
          JSON.stringify({
            userId: partner.id,
            transporter: partner.transporter,
            right: partner.rights,
            comments: partner.comments,
            chat: partner.chat,
            billing: partner.billing,
            state: partner.state,
            ched: partner.ched,
          })
        );
      });
      data.data.documents.forEach((document) => {
        form.append('documents', document.file);
        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: document.id,
            reference: document.reference,
          })
        );
      });

      return api.request({
        method: declareApi.method,
        route: declareApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },

    addDocuments(data: dataReqI) {
      const addDocumentsApi = routesApi.customs.declaration.addDocuments;

      const form: any = new FormData();
      form.append('type', data.data.type);
      data.data.documents.forEach((document, index) => {
        form.append('documents', document.file);

        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: document.id,
            reference: document.reference,
          })
        );
      });

      return api.request({
        method: addDocumentsApi.method,
        route: addDocumentsApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    deleteDocument(data: dataReqI) {
      const deleteDocumentApi = routesApi.customs.declaration.deleteDocument;
      return api.request({
        method: deleteDocumentApi.method,
        route: deleteDocumentApi.path,
        params: data.params,
        data: data.data,
      });
    },
    downloadDocument(data: dataReqI) {
      const downloadDocumentApi =
        routesApi.customs.declaration.downloadDocument;
      return api.request({
        method: downloadDocumentApi.method,
        route: downloadDocumentApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },


    addMandate(data: dataReqI) {
      const addMandateApi = routesApi.customs.declaration.addMandate;
      const form: any = new FormData();
      form.set('title', data.data.title);
      form.set('file', data.data.file);
      // data.data.file.forEach((f) => {
      //     form.append('file', f.file);
      // })

      return api.request({
        method: addMandateApi.method,
        route: addMandateApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },

    getMandates(data: dataReqI) {
      const getMandatesApi = routesApi.customs.declaration.getMandates;
      return api.request({
        method: getMandatesApi.method,
        route: getMandatesApi.path,
        params: data.params,
      });
    },

    downloadMandates(data: dataReqI) {
      const downloadMandatesApi =
        routesApi.customs.declaration.downloadMandates;
      return api.request({
        method: downloadMandatesApi.method,
        route: downloadMandatesApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },

    getAccises(data: dataReqI) {
      const getAccisesApi = routesApi.customs.declaration.getAccises;
      return api.request({
        method: getAccisesApi.method,
        route: getAccisesApi.path,
        params: data.params,
      });
    },

    getAllAccises(data: dataReqI) {
      const getAllAccisesApi = routesApi.customs.declaration.getAllAccises;
      return api.request({
        method: getAllAccisesApi.method,
        route: getAllAccisesApi.path,
        params: data.params,
      });
    },


    getChat(data: dataReqI) {
      const getChatApi = routesApi.customs.declaration.getChat;
      return api.request({
        method: getChatApi.method,
        route: getChatApi.path,
        params: data.params,
      });
    },

    followDeclaration(data: dataReqI) {
      const followDeclarationApi =
        routesApi.customs.declaration.followDeclaration;

      return api.request({
        method: followDeclarationApi.method,
        route: followDeclarationApi.path,
        params: data.params,
        data: data.data, // notification
      });
    },

    unfollowDeclaration(data: dataReqI) {
      const unfollowDeclarationApi =
        routesApi.customs.declaration.unfollowDeclaration;

      return api.request({
        method: unfollowDeclarationApi.method,
        route: unfollowDeclarationApi.path,
        params: data.params,
        data: data.data, // notification
      });
    },
    getCategories(data: dataReqI) {
      const categoriesApi =
        routesApi.customs.declaration.getCategories;
      return api.request({
        method: categoriesApi.method,
        route: categoriesApi.path,
        query: data.query,
        params: data.params,
      });
    },

    sendMessage(data: dataReqI) {
      const sendMessageApi = routesApi.customs.declaration.sendMessage;

      return api.request({
        method: sendMessageApi.method,
        route: sendMessageApi.path,
        params: data.params,
        data: data.data,
      });
    },
  };
};
