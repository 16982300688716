import { Field, Formik } from 'formik';
import React from 'react';
import {
  Divider,
  Form,
  Grid,
  Header,
  Modal,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from 'components/Button';
import { updateVat } from 'interfaces/formSchema/customsValidators';
import { FiscalPeriod, Input } from 'components/Form';


const ModalEditVat = ({
  open,
  actualData,
  handleEditVat,
  handleClose,
}: ModalEditVatProps) => {


  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='edit' content='Edit Vat' />
      <Formik
        initialValues={{
            taxPeriod: actualData.taxPeriod.split('T')[0],
            country: actualData.country,
            clientReference: actualData.clientReference,
        }}
        enableReinitialize={true}
        onSubmit={handleEditVat}
        validationSchema={updateVat}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
        <Modal.Content>
        <Form onSubmit={handleSubmit}>
        <Grid>     
        <Grid.Row columns={2}>
          <Grid.Column>
            <Field
              mandatory
              label='Tax Period'
              name='taxPeriod'
              component={FiscalPeriod}
              type='date'
              meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Field
              mandatory
              label='Client reference'
              name='clientReference'
              placeholder='REF5765...'
              component={Input}
              meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
              }}
            />
          </Grid.Column>
        </Grid.Row>
       
          </Grid>
          <Divider hidden />
          <Divider hidden />
              <Button
                disabled={isSubmitting}
                type='submit'
                content='Submit'
                color='green'
              />
        </Form>
      </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

type declarationData = {
  taxPeriod: string,
  country: {},
  clientReference: string,
};

interface ModalEditVatProps {
  open: boolean;
  actualData: any;
  companyId: number;
  handleEditVat: (values: declarationData) => void;
  handleClose: () => void;
  dispatch: any;
  intl;
}

export default enhance(ModalEditVat);
