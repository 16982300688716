
import { actions as authAction } from './auth';
import { actions as dataAction } from './data';
import { actions as fiscalDeclarationAction } from './fiscal/declaration';
import { actions as fiscalOssAction } from './fiscal/oss';
import { actions as fiscalVatAction } from './fiscal/vat';
import { actions as quickfixesAction } from './fiscal/quickFixes';
import { actions as collectionboxAction } from './fiscal/collectionBox';
import { actions as quickfixesTokenAction } from './fiscal/quickFixes/token';
import { actions as customsDeclarationAction } from './customs/declaration';
import { actions as intrastatAction } from './intrastat';
import { actions as chatAction } from './chat';
import { actions as customsStaticticsAction } from './customs/statistiques';
import { actions as customsClerkAction } from './customs/clerk';
import { actions as socketAction } from './socket';
import { actions as userAction } from './user';
import { actions as serviceAction } from './service';
import { actions as adminAction } from './admin';
import { actions as companyAction } from './company';
import { actions as contactAction } from './company/contact';
import { actions as companyUserAction } from './company/user';
import { actions as companyDriverAction } from './company/driver';
import { actions as companyKeyInfoAction } from './company/keyinfo';
import { actions as companyClerksPricingAction } from './company/clerksPricing';
import { actions as companyIntrastatSettingsAction } from './company/settings/intrastat';
import { actions as companyTaxflowSettingsAction } from './company/settings/taxflow';
import { actions as companyCollectionBoxSettingsAction } from './company/settings/collectionbox';
import { actions as vatAction } from './company/vat';
import { actions as addressAction } from './company/address';
import { actions as companyByToken } from './company/token';
import { actions as companyPayment } from './company/payment';
import { actions as companyInvoiceAction } from './company/invoice';
import { actions as messageAction } from './message';
import { actions as agencyAction } from './agencies';
import { actions as paymentAction } from './payment';
import { actions as contactRequestAction } from './contactRequest';
import { actions as ssrRequestAction } from './internalTools/ssrRequest';
import { actions as commercialAction } from './internalTools/commercial';
import { actions as notificationAction } from './notifications';
import { actions as operatorAction } from './operators';
import { actions as newsAction } from './news'
import { actions as statusAction } from './status'
import { actions as configurationsAction } from './company/configurations';

export default {
  auth: authAction,
  commercial: commercialAction,
  customsDeclaration: customsDeclarationAction,
  customsClerk: customsClerkAction,
  intrastat: intrastatAction,
  chat: chatAction,
  customsStatistics: customsStaticticsAction,
  fiscalDeclaration: fiscalDeclarationAction,
  fiscalOss: fiscalOssAction,
  fiscalVat: fiscalVatAction,
  collectionbox: collectionboxAction,
  quickfixes: quickfixesAction,
  quickfixesToken: quickfixesTokenAction,
  data: dataAction,
  socket: socketAction,
  user: userAction,
  services: serviceAction,
  admin: adminAction,
  company: companyAction,
  contact: contactAction,
  companyByToken: companyByToken,
  companyUser: companyUserAction,
  companyDriver: companyDriverAction,
  companyKeyInfo: companyKeyInfoAction,
  companyClerksPricing: companyClerksPricingAction,
  companyIntrastatSettings: companyIntrastatSettingsAction,
  companyTaxflowSettings: companyTaxflowSettingsAction,
  companyCollectionBoxSettings: companyCollectionBoxSettingsAction,
  companyInvoice: companyInvoiceAction,
  vat: vatAction,
  address: addressAction,
  message: messageAction,
  agency: agencyAction,
  payment: paymentAction,
  companyPayment,
  contactRequest: contactRequestAction,
  ssrRequest: ssrRequestAction,
  notifications: notificationAction,
  operators: operatorAction,
  news: newsAction,
  status: statusAction,
  configurations: configurationsAction,
};
