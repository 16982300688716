import { routesApi } from "config/api";
import { dataReqI } from 'interfaces';

export default ({ api }) => {
  return {
    subscribe(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.subscribe;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
      });
    },
    unsubscribe(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.unsubscribe;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
      });
    },
    getPermissions(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.perms;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
      });
    },
    getCategories(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.getCategories;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params
      });
    },
    getCompanySubscribed() {
      const intrastatApi = routesApi.intrastat.companySubscribed;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
      });
    },
    splitDocument(data: dataReqI){
      const intrastatApi = routesApi.intrastat.split;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    groupFiles(data: dataReqI){
      const intrastatApi = routesApi.intrastat.groupFiles;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    deleteFiles(data: dataReqI){
      const intrastatApi = routesApi.intrastat.deleteFiles;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data,
      });
    },
    changeFileState(data: dataReqI){
      const intrastatApi = routesApi.intrastat.changeFileState;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data,
      });
    },
    renameSendChunk(data: dataReqI){
      const intrastatApi = routesApi.intrastat.renameSendChunk;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    createSingleFile(data: dataReqI){
      const intrastatApi = routesApi.intrastat.singlefile;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    filterTagArchive(data: dataReqI){
      const intrastatApi = routesApi.intrastat.filterTagArchive;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    getIntrastatsByFilters(data: dataReqI){
      const intrastatApi = routesApi.intrastat.getByFilters;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        query: data.query
      });
    },
    filterTag(data: dataReqI){
      const intrastatApi = routesApi.intrastat.filterTag;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    bulkFilter(data: dataReqI){
      const intrastatApi = routesApi.intrastat.bulkFilter;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    archiveAll(data: dataReqI){
      const intrastatApi = routesApi.intrastat.archiveAll;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    addIntrastatDocuments(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.addDocuments;
      const form: any = new FormData();
      form.append('type', data.data.type);
      form.append('split', data.data.split);
      data.data.documents.forEach((e) => {
        e.files.forEach((file) => {
          form.append('documents', file);
        })
        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: e.id,
            number: e.number,
          })
        );
      });

      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    getDocsFromCat(data: dataReqI){
      const intrastatApi = routesApi.intrastat.docsFromCat;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        query: data.query
      });
    },
    deleteDocument(data: dataReqI){
      const intrastatApi = routesApi.intrastat.deleteDoc;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params
      });
    },
    downloadDocument(data: dataReqI){
      const intrastatApi = routesApi.intrastat.getDocument;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data,
        query: data.query,
        responseType: 'blob',
      });
    },
    getAllIntrastats(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.getAllIntrastats;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        query: data.query
      });
    },
    getOneIntrastat(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.getOneIntrastat;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
      });
    },
    getArchiveFile(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.getArchiveFile;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data,
        responseType: 'blob',
      });
    },
    getFilesFullList(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.getFilesFullList;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params
      });
    },
    updateIntrastat(data: dataReqI) {
      const intrastatApi = routesApi.intrastat.updateIntrastat;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    handleTagOperations(data: dataReqI){
      const intrastatApi = routesApi.intrastat.tagOperations;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    createNoneIntrastat(data: dataReqI){
      const intrastatApi = routesApi.intrastat.createNoneIntrastat;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    createClientIntrastat(data: dataReqI) {
      const createintrastatFile = routesApi.intrastat.createIntrastat;
      return api.request({
        method: createintrastatFile.method,
        route: createintrastatFile.path,
        data: data.data,
        params: data.params,
      });
    },
    createIntrastat(data: dataReqI) {
      const createintrastatFile = routesApi.customs.declaration.createIntrastat;
      const form: any = new FormData();
      form.set('none', data.data.none);
      form.set('declarationType', data.data.declarationType);
      form.set('period', data.data.period);
      form.set('filingCountry', data.data.filingCountry);
      form.set('flow', data.data.flow);
      form.set('clientReference', data.data.clientReference);
      form.set('type', data.data.type);
      data.data.documents.forEach((document) => {
        form.append('documents', document.file);
        form.append(
          'documentCategories[]',
          JSON.stringify({
            id: document.id,
            number: document.number,
          })
        );
      });

      return api.request({
        method: createintrastatFile.method,
        route: createintrastatFile.path,
        data: form,
        params: data.params,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      });
    },
    updateStep(data: dataReqI){
      const intrastatApi = routesApi.intrastat.updateStep;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    updateDocument(data: dataReqI) {
      const fiscalVatApi = routesApi.intrastat.updateDocument;
      return api.request({
        method: fiscalVatApi.method,
        route: fiscalVatApi.path,
        params: data.params
      });
    },
    followIntrastat(data: dataReqI){
      const intrastatApi = routesApi.intrastat.followIntrastat;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    settings(data: dataReqI){
      const intrastatApi = routesApi.intrastat.settings;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
    getSettings(data: dataReqI){
      const intrastatApi = routesApi.intrastat.getSettings;
      return api.request({
        method: intrastatApi.method,
        route: intrastatApi.path,
        params: data.params,
        data: data.data
      });
    },
  };
};
