import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { actions, selectors } from 'store';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import InputFlexCustom from 'components/QuickFixes/DeliveryForm/InputFlexCustom';
import Dropdown from 'components/Dropdown';
import { compose } from 'redux';

const Index = (props: transporterProps) => {

  const {
    errors, 
    dispatch, 
    loadingHandlerContact, 
    contacts, 
    userAdminOrOp, 
    setFieldValue, 
    setFieldTouched,  
    touched,  
    names,  
    disabled,  
    companyTransporterName, 
    companyTransporterId, 
    companyTransporterVat, 
    getTransporterVatsValue
  } = props;

  const [companiesId, setCompaniesId]: any = useState(null)
  const [vatId, setVatId]: any = useState(null)

  useEffect(() => {
    if(disabled === false){
      dispatch(actions.quickfixes.getCompanySubscribed());
    }
    return () => {
      dispatch(actions.quickfixes.reset(['contacts', 'error']));
    };

  }, [dispatch, disabled])

  useEffect(() => {
    if(contacts){
      let tab: any = []
      contacts.forEach((e, index) => {
        if(e.transporter){
          tab.push({
            key: index,
            value: e.id,
            text: e.name,
          })
        }      
      })
      tab.length>0 && setCompaniesId(tab)

      let tabs: any = []
      contacts.forEach((el, i) => {
          if(el.Vats.length>0){  
            if(el.Vats[0].Company_Id === companyTransporterId){
              el.Vats.map((vat, j) => (
                tabs.push({
                  key: j,
                  value: vat.id,
                  text: vat.vatNumber,
                })
              ))
            }
          }
        } 
      )
 
      tabs.length>0 && setVatId(tabs)
    }
  }, [contacts, companyTransporterId])
  
  const changeAllValues = (value) => {
    if(value){
      setVatId(null)
      contacts.forEach((e) => {
        if(value === e.id) { 

          getTransporterVatsValue({
            vatId: e.Vats[0].id
          })

          
          setFieldValue(names.name, e.id)
          e.email ? setFieldValue(names.email, e.email) : setFieldValue(names.email, '')
          e.phoneNumber ?setFieldValue(names.phone, e.phoneNumber) : setFieldValue(names.phone, '')
          e.Vats.length>0 ? setFieldValue(names.vat, e.Vats[0].vatNumber) : setFieldValue(names.vat, '')
        }
      })

      let tab: any = []
      contacts.forEach((el) => {
          if(el.Vats.length>0){  
            if(el.Vats[0].Company_Id === value){
              el.Vats.forEach((vat, k) => {
                tab.push({
                  key: k,
                  value: vat.id,
                  text: vat.vatNumber,
                })
              })
            }
          }
        } 
      )
      tab.length>0 && setVatId(tab)
    }
  }
  

  return (
    <>
     {((userAdminOrOp && disabled === false) && (
        <Field
        placeholder={`${companyTransporterName}`}
        name={names.name}
        options={companiesId}
        component={Dropdown}
        loading={loadingHandlerContact}
        width='auto'
        margin='5px'
        meta={{ errors, touched, setFieldValue, setFieldTouched }}
        onChange={(e) => {
          if(e){
            changeAllValues(e)
          }
    
        }}
      />
      )) || ( 
      <Field
        label='Company'
        name={names.name}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        widthLabel='5'
        widthInput='11'
      />
      )}
      <Field
        label='Email'
        name={names.email}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        widthLabel='5'
        widthInput='11'
      />
      <Field
        label='Phone'
        name={names.phone}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        widthLabel='5'
        widthInput='11'
      />
      {((userAdminOrOp && disabled === false) && (
        <Field
          placeholder={`${vatId ? vatId[0].text : companyTransporterVat}`}
          name={names.vat}
          options={vatId}
          loading={vatId ? false: true}
          component={Dropdown}
          width='auto'
          margin='5px'
          meta={{errors, touched, setFieldValue, setFieldTouched}}
          onChange={(e) => {
            if(e){
              getTransporterVatsValue({
                vatId: e
              })
            }
          }}
        />
      )) || (
      <Field
        label='VAT'
        name={names.vat}
        type='text'
        component={InputFlexCustom}
        disabled={disabled}
        meta={{ errors, touched }}
        margin='5px'
        columns='2'
        justifyContent='space-between'
        widthLabel='5'
        widthInput='11'
      />
      )}
     
    </>
  );
};

export interface transporterProps {
  errors: any, 
  dispatch, 
  loadingHandlerContact: any, 
  contacts: any, 
  userAdminOrOp: any, 
  setFieldValue: any, 
  setFieldTouched: any,  
  touched: any,  
  names: any,  
  noAddress: any,  
  disabled: any,  
  companyTransporterName: any, 
  companyTransporterId: any, 
  companyTransporterVat: any, 
  companyId: any,
  getTransporterVatsValue?: (data: any) => void;
}

const mapStateToProps = (state) => {
  return {
    contacts: selectors.contact.contactsSelector(state),
    loadingHandlerContact: selectors.contact.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
