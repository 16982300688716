import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Divider, Header, Grid, Segment } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import LoaderInline from 'components/LoaderInline';
import Button from 'components/Button';
import LinkButton from 'components/core/LinkButton/LinkButton';
import SegmentCustom from 'components/QuickFixes/DeliveryForm/SegmentCustom';
import DashboardDeliveriesTable from 'components/QuickFixes/DeliveryForm/DashboardDeliveriesTable';
import { paths } from 'routes/fiscal/kyanite';
import Wrapper from 'components/core/Wrapper/Wrapper';
import ClickableText from 'components/ClickableText';
import Pagination from 'components/Pagination';

const Index = ({
  history,
  user,
  dispatch,
  numberOfReceived,
  numberOfPending,
  numberOfProofPending,
  totalVatRisk,
  podDeliveries,
  podDeliveriesByFilters,
  companySelected,
  getAllBTSelector,
  match,
  company,
  totalPodOfOneSeller,
}) => {
  const limitPerPage = 20;
  const tab = 'seller';
  const userAdminOrOp = user.operator || user.admin;
  const isSuperOperatorCompany = 49;
  const companyId = userAdminOrOp ? match.params.companyId : companySelected;
  const isAsdFrance = companyId && isSuperOperatorCompany && (Number(companyId) === Number(isSuperOperatorCompany));

  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setData] = useState(null);
  const [countData, setCountData] = useState(0);
  const [activePage, setActivePage] = useState(null);
  const [receivedDeliveries, setReceivedDeliveries] = useState(null);
  const [pendingDeliveries, setPendingDeliveries] = useState(null);
  const [pendingProof, setPendingProof] = useState(null);
  //const [hasPaidSubcription, setHasSubscribe] = useState(null);
  const [vatRisk, setVatRisk] = useState(null);
  const [resetSearch, setresetSearch] = useState(false);

  let savedPage = sessionStorage.getItem('page') ? parseInt(sessionStorage.getItem('page')) : 1;
  let storedSearch = sessionStorage.getItem('search');

  useEffect(() => {
    dispatch(actions.company.getOne({
      params: {
        companyId: companyId
      }
    }))

    if(!sessionStorage.getItem('page')){
      sessionStorage.setItem('page', '1')
    }


    dispatch(
      actions.quickfixes.getTotalVatRisk({
        params: {
          companyId: companyId,
        },
      })
    );

    return () => {
      dispatch(actions.quickfixes.reset(['totalVatRisk', 'company', 'hasSubscribedTo', 'error']));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(actions.quickfixes.reset(['numberOfReceived', 'error']));
    dispatch(
      actions.quickfixes.getNumberOfReceived({
        params: {
          companyId: companyId
        },
      })
    );
    return () => {
      dispatch(actions.quickfixes.reset(['numberOfReceived', 'error']));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(actions.quickfixes.reset(['numberOfPending', 'error']));
    dispatch(
      actions.quickfixes.getNumberOfPending({
        params: {
          companyId: companyId,
        },
      })
    );
    return () => {
      dispatch(actions.quickfixes.reset(['numberOfPending', 'error']));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(actions.quickfixes.reset(['numberOfProofPending', 'error']));
    dispatch(
      actions.quickfixes.getNumberOfProofPending({
        params: {
          companyId: companyId,
        },
      })
    );
    return () => {
      dispatch(actions.quickfixes.reset(['numberOfProofPending', 'error']));
    };
  }, [dispatch, companyId]);


  useEffect(() => {
   if(sessionStorage.getItem('search')){
      dispatch(
        actions.quickfixes.getPODOfOneSellerByFilters({
          params: {
            companyId: companyId,
          },
          data: [
            JSON.parse(sessionStorage.getItem('search')), 
            {query: {
              limit: limitPerPage,
              page: savedPage
            }}
          ]
        })
      );
      return () => {
        dispatch(
          actions.quickfixes.reset([
            'podOfOneSellerByFilters',
            'error',
          ])
        );
      }
     
   }else{
      dispatch(
        actions.quickfixes.getPODOfOneSeller({
          params: {
            companyId,
          },
          query: {
            limit: limitPerPage,
            page: sessionStorage.getItem('page') ? sessionStorage.getItem('page') : 1,
            type: isAsdFrance ? 'seller' : 'buyer',
            // isNotOwner: isAsdFrance || userAdminOrOp
          },
        })
      )
    }
    
    dispatch(
      actions.socket.subscribeUpdateDeliveryCompany(companyId)
    );
    dispatch(
      actions.socket.subscribeUpdateNumberOfDeliveryReceiveCompany(companyId)
    );
    dispatch(
      actions.socket.subscribeUpdateNumberOfProofPendingCompany(companyId)
    );
    dispatch(
      actions.socket.subscribeUpdateNumberOfDeliveryPendingCompany(companyId)
    );
    dispatch(actions.socket.subscribeUpdateTotalVatRiskCompany(companyId));

    return () => {
      dispatch(
        actions.quickfixes.reset([
          'podOfOneSeller',
          'totalPodOfOneSeller',
          'podOfOneSellerByFilters',
          'error',
        ])
      );
      dispatch(actions.socket.unsubscribeUpdateDeliveryCompany(companyId));
      dispatch(
        actions.socket.unsubscribeUpdateNumberOfDeliveryReceiveCompany(
          companyId
        )
      );
      dispatch(
        actions.socket.unsubscribeUpdateNumberOfProofPendingCompany(companyId)
      );
      dispatch(
        actions.socket.unsubscribeUpdateNumberOfDeliveryPendingCompany(
          companyId
        )
      );
      dispatch(actions.socket.unsubscribeUpdateTotalVatRiskCompany(companyId));
    };
  }, [dispatch, companyId, isAsdFrance, savedPage]);

  useEffect(() => {
    setReceivedDeliveries(numberOfReceived);
    // const isProduct = 
    //           company &&
    //           company.Subscriptions && 
    //           company.Subscriptions.length>0 && 
    //           company.Subscriptions.find((e) => e.Service_Id === 3)

    //isProduct && setHasSubscribe(isProduct.Products.length>0)
  }, [numberOfReceived, user, company]);

  useEffect(() => {
    setPendingDeliveries(numberOfPending);
  }, [numberOfPending]);

  useEffect(() => {
    setPendingProof(numberOfProofPending);
  }, [numberOfProofPending]);

  useEffect(() => {
    setVatRisk(totalVatRisk);
  }, [totalVatRisk]);

  // useEffect(() => {
  //   setHasSubscribe(hasPaidSubscription)
  // }, [hasPaidSubscription])

  useEffect(() => {
    if (podDeliveriesByFilters || podDeliveries) {
      let deliveries
      if(podDeliveriesByFilters && podDeliveriesByFilters.deliveries){
        deliveries = podDeliveriesByFilters.deliveries
        setCountData(podDeliveriesByFilters.total)
      }else if(podDeliveries !== null){
        setCountData(podDeliveries.count)
        deliveries = podDeliveries.rows
      }
      deliveries && setData(_.orderBy(deliveries, ['createdAt'], ['desc']));
    }
    resetSearch && setresetSearch(false);
  }, [podDeliveries, podDeliveriesByFilters, resetSearch]);


  // search
  const applyFilters = (data, dataPage: number) => {
    let queryTab = []
    let selectedPage = typeof dataPage === 'number' 
                        ? dataPage 
                        : parseInt(sessionStorage.getItem('page'))

    queryTab.push(data)
    queryTab.push({
      query: {
        limit: limitPerPage,
        page: selectedPage
      },
    })

    dispatch(actions.quickfixes.reset(['podOfOneSellerByFilters']));
    dispatch(
      actions.quickfixes.getPODOfOneSellerByFilters({
        params: {
          companyId,
        },
        data: queryTab,
      })
    );
    sessionStorage.removeItem('search');
    sessionStorage.setItem('search', JSON.stringify(queryTab[0]));
  };

  const downloadMassIntegrationTemplate = () => {
    dispatch(
      actions.quickfixes.downloadMassIntegrationTemplate({
        params: {
          companyId,
        },
      })
    );
  };

  const resetFilters = () => {
    sessionStorage.removeItem('search');
    dispatch(actions.quickfixes.reset(['podOfOneSellerByFilters']));
    dispatch(
      actions.quickfixes.getPODOfOneSeller({
        params: {
          companyId,
        },
        query: {
          limit: limitPerPage,
          page: 1,
          type: isAsdFrance ? 'seller'  : 'buyer'
        },
      })
    )
    sessionStorage.setItem('page', Math.ceil(1).toString());
    setActivePage(true)
  };

  const handleSort = (name) => {
    if (column !== name) {
        setcolumn(name);
        setdirection('descending');
        setData(_.sortBy(data, [name]));
      return;
    }
    setData(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  
  const handlePageChange = (event, { activePage }) => {
   if(event){
      if(sessionStorage.getItem('search')){
        let data = JSON.parse(sessionStorage.getItem('search'))
        applyFilters(data, activePage)
      }else{
        dispatch(
          actions.quickfixes.getPODOfOneSeller({
            params: {
              companyId,
            },
            query: {
              limit: limitPerPage,
              page: Math.ceil(activePage),
              type: isAsdFrance ? 'seller' : 'buyer'
            },
          })
        );
      }
      sessionStorage.setItem('page', Math.ceil(activePage).toString());
   }
  };

  return (
    <Wrapper textAlign='center' margin='auto' width='95%'>

      <Container>
        <Divider hidden />
        {userAdminOrOp && (
          <> 
          <Divider hidden/>
          <Button
            floated='left'
            labelPosition='left'
            icon='angle left'
            position='left'
            type='button'
            content='Back'
            margin='5px'
            onClick={() =>
              history.push(paths.quickFixes)
            }
          /></>
        )}
        <Wrapper>
          <Header size='huge'>
            Quickproof
            <Header.Subheader>Dashboard (seller)</Header.Subheader>
          </Header>
        </Wrapper>

        <Divider hidden />
        {/* {((isAsdFrance === false && hasPaidSubcription) || userAdminOrOp) && ( */}
        {((isAsdFrance === false) || userAdminOrOp) && (
        <Grid columns={3} centered>
          <Grid.Column>
            <Wrapper textAlign='center'>
              <LinkButton
                fluid
                content='Start a delivery'
                color='blue'
                size='huge'
                path={
                  userAdminOrOp
                    ? paths.startDeliveryOperator.replace(
                        ':companyId',
                        companyId
                      )
                    : paths.startDelivery
                }
              />
              <Container textAlign='center'>
                (Your deliveries must be listed)
              </Container>
              <Divider hidden />
              <LinkButton
                fluid
                content='Mass integrations'
                color='blue'
                size='huge'
                path={
                  userAdminOrOp
                    ? paths.massIntegrationsOperator.replace(
                        ':companyId',
                        companyId
                      )
                    : paths.massIntegrations
                }
              />
              <Container textAlign='center'>
                (
                <ClickableText onClick={downloadMassIntegrationTemplate}>
                  Download CSV file template
                </ClickableText>
                )
              </Container>
            </Wrapper>
          </Grid.Column>
        </Grid>
        )}
        <Wrapper padding='10px' display='flex' justifyContent='flex-end'>
          <ClickableText
            color='black'
            onClick={() => {
              window.open(
                'https://my-asd.s3-eu-west-1.amazonaws.com/kyanite/user_guide.pdf',
                '_blank'
              );
            }}
          >
            User guide
          </ClickableText>
        </Wrapper>
        <Grid columns={4} centered>
          <Grid.Column>
            <SegmentCustom>
              <Header size='huge' color='red' textAlign='center'>
                {(receivedDeliveries === null && <LoaderInline />) || (
                  <>
                    {receivedDeliveries}
                    <Header.Subheader>
                      {receivedDeliveries > 1
                        ? `Deliveries have been received by your buyer`
                        : `Delivery has been received by your buyer`}
                    </Header.Subheader>
                  </>
                )}
              </Header>
            </SegmentCustom>
            <Segment basic />
          </Grid.Column>
          <Grid.Column>
            <SegmentCustom>
              <Header size='huge' color='red' textAlign='center'>
                {(pendingProof === null && <LoaderInline />) || (
                  <>
                    {pendingProof}
                    <Header.Subheader>
                      {pendingProof > 1
                        ? `Proofs of delivery have been uploaded`
                        : `Proof of delivery has been uploaded`}
                    </Header.Subheader>
                  </>
                )}
              </Header>
            </SegmentCustom>
          </Grid.Column>
          <Grid.Column>
            <SegmentCustom>
              <Header size='huge' color='red' textAlign='center'>
                {(pendingDeliveries === null && <LoaderInline />) || (
                  <>
                    {pendingDeliveries}
                    <Header.Subheader>
                      {pendingDeliveries > 1
                        ? `Deliveries are awaiting proof`
                        : `Delivery is awaiting proof`}
                    </Header.Subheader>
                  </>
                )}
              </Header>
            </SegmentCustom>
          </Grid.Column>
          <Grid.Column>
            <SegmentCustom>
              <Header size='huge' color='red' textAlign='center'>
                {(vatRisk === null && <LoaderInline />) || (
                  <>
                    {`${+vatRisk.toFixed(4)} €`}
                    <Header.Subheader>Of VAT risk</Header.Subheader>
                  </>
                )}
              </Header>
            </SegmentCustom>
          </Grid.Column>
        </Grid>
      </Container>
      <Divider hidden/>
      <Divider hidden/>
      <Divider hidden/>   
        <DashboardDeliveriesTable
          tab={tab}
          user={user}
          data={data}
          getAllBTSelector={getAllBTSelector}
          history={history}
          companyId={companyId}
          handleSort={handleSort}
          column={column}
          direction={direction}
          dispatch={dispatch}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
          storedSearch={storedSearch}
          totalPod={countData}
        />               
      <Divider hidden />
      {countData>0 && (
        <Pagination
          totalPages={countData / 10}
          storedPage={(sessionStorage.getItem('page') !== null ? parseInt(sessionStorage.getItem('page')) : 1)}
          resetPagination={activePage}
          onChange={handlePageChange}
        />
      )}   
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    company: selectors.company.companySelector(state),
    numberOfReceived: selectors.quickfixes.numberOfReceivedSelector(state),
    numberOfPending: selectors.quickfixes.numberOfPendingSelector(state),
    numberOfProofPending: selectors.quickfixes.numberOfProofPendingSelector(state),
    totalVatRisk: selectors.quickfixes.totalVatRiskSelector(state),
    podDeliveries: selectors.quickfixes.podOfOneSellerSelector(state),
    totalPodOfOneSeller: selectors.quickfixes.totalPodOfOneSellerSelector(state),
    podDeliveriesByFilters: selectors.quickfixes.podOfOneSellerByFiltersSelector(state),
    companySelected: selectors.auth.companySelected(state),
    user: selectors.auth.userSelector(state),
    getAllBTLoadingSelector: selectors.quickfixes.getAllBTLoadingSelector(state),
    getAllBTSelector: selectors.quickfixes.getAllBTSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
