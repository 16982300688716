import React, { useState, useEffect } from 'react';
import {
  Segment,
  Divider,
  Checkbox,
  Form,
  Header,
  Grid,
  Message,
} from 'semantic-ui-react';
import { Field } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import CustomInput from './CustomInput';
import Dropdown from './Dropdown';
import Button from 'components/Button';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import Wrapper from 'components/core/Wrapper/Wrapper';
import NewCompanyModal from 'views/Companies/New/NewCompanyModal/NewCompanyModal';

const Index = ({
  dispatch,
  setFieldValue,
  setFieldTouched,
  manageSteps,
  errors,
  touched,
  values,
  customsData,
  contactsTransporters,
  companyId,
  contact,
  setTransporter,
  transporterId,
  errorHandlerContact,
  loadingHandlerContact,
}: any) => {
  const [data, setData] = useState([]);
  const [checkboxState, setCheckboxState] = useState(
    null
  );
  const [selectedTransporter, setSelectedTransporter] = useState(transporterId);
  const [vatList, setVatList] = useState([]);
  const [createContactModal, setCreateContactModal] = useState(false);

  useEffect(() => {
    dispatch(
      actions.contact.getAllContactTransporters({
        params: { companyId },
      })
    );

    if(customsData && customsData.transporter !== 49){
      dispatch(
        actions.contact.getBothContact({
          params: { 
            companyId: companyId, 
            contactId: customsData.transporter },
        })
      );
    }
  
    

    return () => {
      dispatch(
        actions.contact.reset(['contactsTransporters', 'contact', 'error'])
      );
    };
  }, [dispatch, companyId, customsData]);

  useEffect(() => {
    if (
      selectedTransporter !== '' &&
      values.transport.transporter.name !== selectedTransporter &&
      selectedTransporter !== null
    ) {
      dispatch(
        actions.contact.getOneContact({
          params: { companyId: companyId, contactId: selectedTransporter },
        })
      );
    }
    return () => {
      dispatch(actions.contact.reset(['contact', 'error']));
    };
  }, [
    selectedTransporter, 
    companyId, 
    dispatch, 
    values.transport.transporter.name
  ]);

  useEffect(() => {
    // anyone contacts who are transporters
    contactsTransporters && !data.length &&
      setData(
        contactsTransporters.map((contact, index) => {
          return {
            key: index,
            value: contact.id,
            text: contact.name,
          };
        })
      );

       // new if transporter from GB does not have VAT Number (null)
       customsData && contactsTransporters && contactsTransporters.map((c) => {
        if(customsData.transporter === c.id){
          setCheckboxState('Me')
          if(contact && contact.Vats.length>0){
            setFieldValue('transporterId', c.id)
            setFieldValue('transport.transporter.vatId', contact.Vats[0].id)
          }else{
            setFieldValue('transporterId', customsData.transporter)
            setFieldValue('transport.transporter.vatId', null)
          }
          setSelectedTransporter(c.id)
        }
      })

    

  }, [contactsTransporters, data, contact, customsData, setFieldValue]);

  useEffect(() => {
    let vatsArr = [];
    if (contact) {
      contact.id === values.transporterId && manageTransporterValues(contact);

      if (contact.Vats) {
        contact.Vats.forEach((vat, index) => {
          vatsArr.push({
            key: index,
            value: vat.id,
            text: vat.vatNumber,
            flag: vat.country.toLowerCase(),
          });
        });
        setVatList(vatsArr);
        if (vatsArr.length === 1 && selectedTransporter) {
          setFieldValue('transport.transporter.vatId', vatsArr[0].value);
          manageVatChange(vatsArr[0].value);
        } else if (
          !vatsArr.find(
            (vat) => vat.value === values.transport.transporter.vatId
          )
        ) {
          setFieldValue('transport.transporter.vatId', '');
          setFieldTouched('transport.transporter.vatId', '');
          manageVatChange('');
        }
      }
    }
  }, [contact]);

  const manageTransporterValues = (contact) => {
    setFieldValue('transport.transporter.name', contact.name);
    setFieldValue('transport.transporter.email', contact.email);
    setFieldValue('transport.transporter.phone', contact.phoneNumber || '');
  };

  const manageTransporterChange = (value) => {
    setSelectedTransporter(value);
    setTransporter(value);
    setFieldValue('transport.transporter.vatId', null);
    if (value === '') {
      setFieldValue('transport.transporter.company', '');
      setFieldValue('transport.transporter.address', '');
      setFieldValue('transport.transporter.name', '');
      setFieldValue('transport.transporter.email', '');
      setFieldValue('transport.transporter.phone', '');
      setFieldValue('transport.transporter.vatId', '');
      setFieldValue('transport.transporter.vat', '');
      setFieldValue('transport.transporter.vatCountry', '');
    }
  };

  const manageVatChange = (value) => {
    if (value === '') {
      setFieldValue('transport.transporter.vatCountry', '');
      setFieldValue('transport.transporter.vat', '');
    } else {
      contact.Vats.map((vat) => {
        if (value === vat.id) {
          setFieldValue('transport.transporter.vatCountry', vat.country);
          setFieldValue('transport.transporter.vat', vat.vatNumber);
        }
      });
    }
  };

  const manageBuyerSelection = () => {
    setFieldValue(
      'transport.shippingAgent.agent',
      values.transport.shippingAgent.receiverId
    );
    setFieldValue('transport.shippingAgent.agentSelected', 'The buyer');
    setFieldValue('transport.shippingAgent.estimatedDeliveryPrice', null);
    setFieldValue('transport.shippingAgent.currency', null);
    setFieldValue('transporterId', null);
    setFieldValue('transport.transporter.company', '');
    setFieldValue('transport.transporter.address', '');
    setFieldValue('transport.transporter.name', '');
    setFieldValue('transport.transporter.email', '');
    setFieldValue('transport.transporter.phone', '');
    setFieldValue('transport.transporter.vatId', '');
    setFieldValue('transport.transporter.vatCountry', '');
    setFieldValue('transport.transporter.vat', '');
  };

  return (
    <>
      {checkboxState !== 'Me' && (
        <Wrapper width='30%' margin='auto' minWidth='400px'>
          <Segment>
            <Header size='tiny'>
              <MandatoryFieldLabel content='Who is the transport organizer?' />
            </Header>
            <Wrapper display='flex' justifyContent='space-evenly'>
              <Checkbox
                radio
                label='Me'
                name='checkboxRadioGroup'
                value='Me'
                checked={ checkboxState === 'Me'}
                onChange={(e, { value }) => {
                  setCheckboxState(value);
                  setFieldValue('transport.shippingAgent.agent', companyId);
                  setFieldValue('transport.shippingAgent.agentSelected', 'Me');
                  setFieldValue('transport.shippingAgent.currency', 'EUR');
                }}
              />
              <Checkbox
                radio
                label='The buyer'
                name='checkboxRadioGroup'
                value='The buyer'
                checked={checkboxState === 'The buyer'}
                onChange={(e, { value }) => {
                  setCheckboxState(value);
                  manageBuyerSelection();
                }}
              />
            </Wrapper>
            <Divider />
            <Field
              label='Receiver name'
              name={'transport.shippingAgent.receiverName'}
              disabled
              type='text'
              component={CustomInput}
              meta={{ errors, touched }}
              margin='15px'
              columns='2'
              labelVerticalPosition='center'
              justifyContent='space-between'
            />

            <Field
              label='Address Country'
              name={'transport.shippingAgent.addressCountry'}
              disabled
              type='text'
              component={CustomInput}
              meta={{ errors, touched }}
              margin='15px'
              columns='2'
              labelVerticalPosition='center'
              labelPosition='left'
              justifyContent='space-between'
            />
            <Field
              label='Email'
              name={'transport.shippingAgent.email'}
              disabled
              type='text'
              component={CustomInput}
              meta={{ errors, touched }}
              margin='15px'
              columns='2'
              labelVerticalPosition='center'
              justifyContent='space-between'
            />
            <Field
              label='Phone'
              name={'transport.shippingAgent.phone'}
              disabled
              type='text'
              component={CustomInput}
              meta={{ errors, touched }}
              margin='15px'
              columns='2'
              labelVerticalPosition='center'
              justifyContent='space-between'
            />
          </Segment>
        </Wrapper>
      )}

      {checkboxState === 'Me' && (
        <Grid columns='2'>
          <Grid.Column>
            <Segment>
              <Header size='tiny'>
                <MandatoryFieldLabel content='Who is the transport organizer?' />
              </Header>
              <Checkbox
                radio
                label='Me'
                name='checkboxRadioGroup'
                value='Me'
                checked={checkboxState === 'Me'}
                onChange={(e, { value }) => {
                  setCheckboxState(value);
                  setFieldValue('transport.shippingAgent.agent', companyId);
                  setFieldValue('transport.shippingAgent.agentSelected', 'Me');
                  setFieldValue('transport.shippingAgent.currency', 'EUR');
                }}
              />

              <Checkbox
                radio
                label='The buyer'
                name='checkboxRadioGroup'
                value='The buyer'
                checked={checkboxState === 'The buyer'}
                onChange={(e, { value }) => {
                  setCheckboxState(value);
                  manageBuyerSelection();
                }}
              />
              <Divider />
              <Field
                label='Receiver name'
                name={'transport.shippingAgent.receiverName'}
                disabled
                type='text'
                component={CustomInput}
                meta={{ errors, touched }}
                margin='15px'
                columns='2'
                labelVerticalPosition='center'
                justifyContent='space-between'
              />
              <Field
                label='Address Country'
                name={'transport.shippingAgent.addressCountry'}
                disabled
                type='text'
                component={CustomInput}
                meta={{ errors, touched }}
                margin='15px'
                columns='2'
                labelVerticalPosition='center'
                labelPosition='left'
                justifyContent='space-between'
              />
              <Field
                label='Email'
                name={'transport.shippingAgent.email'}
                disabled
                type='text'
                component={CustomInput}
                meta={{ errors, touched }}
                margin='15px'
                columns='2'
                labelVerticalPosition='center'
                justifyContent='space-between'
              />
              <Field
                label='Phone'
                name={'transport.shippingAgent.phone'}
                disabled
                type='text'
                component={CustomInput}
                meta={{ errors, touched }}
                margin='15px'
                columns='2'
                labelVerticalPosition='center'
                justifyContent='space-between'
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            {data && (
              <Segment>
                <Header size='tiny'>Transporter information</Header>
                <>
                  {createContactModal && (
                    <NewCompanyModal
                      open={createContactModal}
                      handleClose={() => setCreateContactModal(false)}
                      addContact
                      companyId={companyId}
                    />
                  )}
                  <Wrapper display='flex' padding='0 3.6rem'>
                    <Button
                      icon='plus'
                      type='submit'
                      content='Create'
                      onClick={() => {
                        setCreateContactModal(true);
                      }}
                    />
                  </Wrapper>
                  {/* <MandatoryFieldLabel content='Transporter' /> */}
                  <span>Transporter</span>
                  <Form.Field>
                    <Field
                      placeholder='Choose a transporter'
                      name={`transporterId`}
                      options={data}
                      component={Dropdown}
                      loading={loadingHandlerContact}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      setterFunction={manageTransporterChange}
                      uniqueError={errorHandlerContact.includes(
                        'contactsTransporters'
                      )}
                    />
                  </Form.Field>
                  {data.length === 0 && (
                    <Wrapper padding='1rem 3.6rem'>
                      <Message negative>
                        To select a transporter, you must have it in your
                        contact list.
                      </Message>
                    </Wrapper>
                  )}
                </>
              </Segment>
            )}
            <Divider hidden />

            {contact !== null &&
              contact.Vats !== null &&
              selectedTransporter !== null &&
              selectedTransporter !== '' && (
                <>
                  <Segment>
                    <Field
                      label="Transporter's VAT"
                      placeholder="Choose the transporter's VAT"
                      name='transport.transporter.vatId'
                      loading={loadingHandlerContact}
                      options={vatList}
                      component={Dropdown}
                      meta={{ errors, touched, setFieldValue, setFieldTouched }}
                      setterFunction={manageVatChange}
                    />
                  </Segment>
                  <Segment>
                    <Field
                      label={
                        <MandatoryFieldLabel content='Estimated shipping price (€)' />
                      }
                      name={'transport.shippingAgent.estimatedDeliveryPrice'}
                      type='text'
                      component={CustomInput}
                      meta={{ errors, touched }}
                      columns='2'
                      labelVerticalPosition='center'
                      justifyContent='space-between'
                    />
                  </Segment>
                </>
              )}
          </Grid.Column>
        </Grid>
      )}

      <Divider hidden />
      <Button
        type='button'
        size='large'
        icon='right arrow'
        labelPosition='right'
        color='blue'
        content='Next'
        margin='5px'
        onClick={() => manageSteps(5)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: selectors.contact.contactSelector(state),
    contactsTransporters: selectors.contact.getBothContatcsSelector(state),
    errorHandlerContact: selectors.contact.errorSelector(state),
    loadingHandlerContact: selectors.contact.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
