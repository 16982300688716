import { Field, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Divider,
  Form,
  Grid,
  Header,
  List,
  Modal,
  Transition,
} from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { File, FiscalPeriod, Input } from 'components/Form';
import { createVatDeclarationsSchema } from 'interfaces/formSchema/vatDeclarationValidators';
import styled from '@emotion/styled';


const Index = ({
  user,
  companyId,
  companyName,
  obligations,
  dispatch,
  intl,
  selectedCountry,
  open,
  handleClose,
  categoriesDocuments,
  categoriesLoading,
}) => {

  const [categoriesDocumentsList, setCategoriesDocumentsList]: any = useState([]);
  const [documentList, setDocumentList]: any = useState(0);
  const [countriesList, setcountriesList]: any = useState([]);

  useEffect(() => {
    dispatch(actions.fiscalVat.getCompanySubscribed());
    dispatch(actions.fiscalVat.getCategories({
      params: {
        companyId: companyId
      }
    }));
    dispatch(actions.fiscalVat.getDocsFromCat({
      query: {type: 0}
    }));

    selectedCountry && dispatch(actions.fiscalVat.getObligation({
      params: {
        companyId: companyId,
        countryId: selectedCountry.id
      }
    }));

    return () => {
      dispatch(
        actions.fiscalVat.reset([
          'subscribed',
          'obligation',
          'categories',
          'docsFromCat',
          'error',
        ]));
    };
  }, [dispatch, companyId, selectedCountry]);

  useEffect(() => {
    if(selectedCountry && obligations){
      setcountriesList({
        iso: selectedCountry.country_code, 
        periodicity: obligations.periodicity,
        Country_Id: selectedCountry.id
      })
    }
  }, [selectedCountry, obligations, intl]);


  useEffect(() => {
    if(categoriesDocuments){
      setCategoriesDocumentsList(
        categoriesDocuments.map((category, index) => {
          return {
            key: index,
            value: category.id,
            text: category.name,
          };
        })
      );
    }
  }, [categoriesDocuments]);


  const createVatDeclaration = async (values) => {
    dispatch(
      actions.fiscalVat.createVatDeclaration({
        params: {
          companyId: companyId,
        },
        data: {
          taxPeriod: values.taxPeriod,
          country: values.country,
          obligations: values.obligations,
          clientReference: values.clientReference, 
          ...(values.documents && { 
            documents: values.documents 
          }),
        },
      })
    )
      handleClose()
  }
   
  const Alert = styled.div(() => ({
    fontSize: '12px',
    textAlign: 'center',
    padding: '0 1rem',
    margin: '0.5rem 0',
    lineHeight: '12px',
  }));


  return (
    <>
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content={`New Vat Declaration - ${companyName}`} />
      <Formik
        enableReinitialize={true} 
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          companyId: companyId,
          country: countriesList,
          taxPeriod: '',
          clientReference: '', 
          documents: [],
        }}
        onSubmit={createVatDeclaration}
        validationSchema={createVatDeclarationsSchema}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
        }) => (
          <Modal.Content>
        <Form onSubmit={handleSubmit}>   
        <Grid>
        <Grid.Row columns={2}>
        <Grid.Column>
          <Field
            mandatory
            label='Tax Period'
            name='taxPeriod'
            component={FiscalPeriod}
            type='date'
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column> 
        <Grid.Column>
            <Field
              mandatory
              label='Client reference'
              name='clientReference'
              placeholder='REF5765...'
              component={Input}
              meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
              }}
            />
          </Grid.Column>    
        </Grid.Row>

          </Grid>
            <Divider hidden />
            <Header size='tiny' textAlign='center'>Documents</Header>
            <Alert>Warning: at this stage, you can upload only one file. But you can upload your other files once the {values.type === 'accises' ? 'accises declaration' : 'customs declaration'} file is created.</Alert>
                  <FieldArray
                    name='documents'
                    render={(arrayHelpers) => (
                      <>
                      <Transition.Group
                        as={List}
                        duration={200}
                        size='medium'
                        verticalAlign='middle'
                      >
                        <Grid columns='4'>
                          {values.documents.length > 0 &&
                            values.documents.map((document, index) => {
                  
                              setDocumentList(index+1)
                              return (
                                <Grid.Row key={index}>
                                  <Grid.Column width='5'>
                                    <Field
                                      name={`documents[${index}].id`}
                                      arrayName='documents'
                                      placeholder='Choose a Category'
                                      component={Dropdown}
                                      options={categoriesDocumentsList}
                                      loading={categoriesLoading}
                                      meta={{
                                        errors,
                                        touched,
                                        setFieldValue,
                                        setFieldTouched,
                                      }}
                                    />
                                  </Grid.Column>
                                  <Grid.Column width='9'>
                                    <Field
                                      name={`documents[${index}].file`}
                                      component={File}
                                      error={errors.documents?.[index]?.file}
                                      touched={touched.documents?.[index]?.file}
                                      meta={{
                                        setFieldValue,
                                        setFieldTouched,
                                      }}
                                    />
                                  </Grid.Column>
                                  <Grid.Column width='2'>
                                    <Button
                                      icon='minus'
                                      type='button'
                                      color='red'
                                      onClick={() => {
                                        arrayHelpers.remove(index)
                                        setDocumentList(0)
                                      }}
                                      maxHeight='2.7rem'
                                    />
                                  </Grid.Column>
                                  <Divider hidden />
                                </Grid.Row>
                              );
                            })}
                        </Grid>
                        <Divider hidden />                                                                  
                      </Transition.Group>
                        {documentList === 0 && (
                          <Button
                            icon='plus'
                            type='button'
                            onClick={() =>                       
                                arrayHelpers.push({
                                  file: undefined,
                                  id: undefined,
                                  number: '',
                                })                           
                            }
                            margin='0px'
                            position='center'
                          />
                        )}
                      </>
                    )}
                  />                    
            <Divider hidden />
            <Button type='submit' content='Submit' />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companies: selectors.fiscalVat.subscribedSelector(state),
    companiesLoading: selectors.fiscalVat.subscribedLoadingSelector(state),
    obligationsloading: selectors.fiscalVat.obligationLoadingSelector(state),
    obligations: selectors.fiscalVat.obligationGetSelector(state),
    categoriesDocuments: selectors.fiscalVat.getDocsFromCatSelector(state),
    categoriesLoading: selectors.fiscalVat.getDocsFromCatLoadingSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    errorHandlerCompany: selectors.company.errorSelector(state),
    errorHandlerDeclaration: selectors.fiscalDeclaration.errorSelector(state),
    euCountries: selectors.data.euCountriesSelector(state),
    euCountriesLoading: selectors.data.euCountriesLoadingSelector(state),
    categoriesD: selectors.fiscalVat.getCategoriesSelector(state),
    categoriesDLoading: selectors.fiscalVat.getCategoriesLoadingSelector(state),
  };
  
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
