import styled from "@emotion/styled";

export const Consignation = styled.div(() => ({
    width: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  }))

  export const NoteWrapper = styled.div(() => ({
    width: '80px',
    marginRight: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }))

  export const Pochette = styled.div(() => ({
    width: '80px',
    marginRight: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }))

  export const Note = styled.div`
    width: 25px;
    height: 25px;
    margin: 0 4px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    border-radius: 15px;
    line-height: 25px;
    color: #fff;
    background: ${props => props.color};
   
    `