import { selectors } from "store/common";

const {
  createErrorsSelector,
  createLoadingSelector,
  createSuccessSelector
} = selectors;

// Errors
const updateCountriesErrorSelector = state => 
  state.companyTaxflowSettings.errors.createCountries;
const getCountriesErrorSelector = state => 
  state.companyTaxflowSettings.errors.countries;

const errorsSelector = createErrorsSelector([
  updateCountriesErrorSelector,
  getCountriesErrorSelector
]);

// Loading
const updateCountriesLoadingSelector = state => 
  state.companyTaxflowSettings.loading.createCountries;
const getCountriesLoadingSelector = state => 
  state.companyTaxflowSettings.loading.countries;

const loadingSelector = createLoadingSelector([
  updateCountriesLoadingSelector,
  getCountriesLoadingSelector
]);

// Success
const updateCountriesSuccessSelector = state => 
  state.companyTaxflowSettings.success.createCountries;
const getCountriesSuccessSelector = state => 
  state.companyTaxflowSettings.success.countries;

const successSelector = createSuccessSelector([
  updateCountriesSuccessSelector,
  getCountriesSuccessSelector
]);

// Settings
const errorSelector = state => state.companyTaxflowSettings.error;
const updateCountriesSelector = state => 
  state.companyTaxflowSettings.createCountries;
const getCountriesSelector = state => 
  state.companyTaxflowSettings.countries;

export default {
  errorsSelector,
  loadingSelector,
  successSelector,
  errorSelector,
  updateCountriesSelector,
  getCountriesSelector,
  getCountriesLoadingSelector
};
