import React, { useState, useRef, useEffect } from 'react';
import { Divider, Form, Modal, Table, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import { Input } from 'components/Form';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Dropdown from 'components/Dropdown';
import EmptyTable from 'components/EmptyTable';
import TextCustom from 'components/core/TextCustom/TextCustom';

const BillingTable = ({
  dispatch,
  match,
  billings,
  billingsLoading,
  companyId,
  fileHasBillings,
  addInvoicingLineLoading,
  updateInvoicingLineLoading,
  removeInvoicingLineLoading,
  file,
  countryVatRate,
  pricing,
  pricingLoading
}: any) => {
  const [billingsList, setbillingList] = useState([]);
  const [selectedBillingCategory, setselectedBillingCategory] = useState(null);
  const [customsAmount, setcustomsAmount] = useState(null);
  const [data, setdata] = useState([]);
  const [editLine, seteditLine] = useState(false);
  const [editQuantity, seteditQuantity] = useState(null);
  const [isFileValidated, setisFileValidated] = useState(false);
  const formikRef = useRef();

  useEffect(() => {
    dispatch(actions.customsClerk.getBillings());
    dispatch(actions.companyClerksPricing.getOneClerksPricing({
      params: { companyId: companyId },
    }))
    dispatch(
      actions.customsClerk.getFileHasBillings({
        params: {
          fileId: match.params.fileId,
        },
      })
    );

    dispatch(
      actions.socket.subscribeUpdateCustomsClerkFilesBillings(
        match.params.fileId
      )
    );

    return () => {
      dispatch(actions.companyClerksPricing.reset(['pricing', 'error']));
      dispatch(actions.data.reset(['countryVatRate', 'error']));
      dispatch(
        actions.customsClerk.reset(['fileHasBillings', 'billings', 'error'])
      );
      dispatch(
        actions.socket.unsubscribeUpdateCustomsClerkFilesBillings(
          match.params.fileId
        )
      );
    };
  }, [dispatch, companyId, match]);

  useEffect(() => {
    const country = file?.CustomsDeclaration?.Company?.country;

    if (country === 'FR') {
      dispatch(
        actions.data.getCountryVatRate({
          data: {
            country: 'FR',
          },
        })
      );
    }
    if (file?.validated) {
      setisFileValidated(true);
    }
  }, [dispatch, file]);

   useEffect(() => {
    if(pricing && pricing.customized){
      let billing: any = []
      pricing.customized.forEach((el) => {
        if(el.inCustoms === null){
          billing.push({
            key: el.id,
            value: el.id,
            text: `${el.category} ${
              el.description ? `(${el.description})` : ''
            }`,
          })
        }
      })
      setbillingList(billing)
    }
   }, [pricing])

  useEffect(() => {
    if (fileHasBillings) {
      setdata(fileHasBillings);
    }
  }, [fileHasBillings]);

  useEffect(() => {
    if (!editLine) {
      if(pricing && pricing.customized.length){
        const price = pricing.customized.find((price) => 
          (price.id === selectedBillingCategory));
          price && setcustomsAmount(price.amount);
      }else if(billings){    
        const billing = billings.find((billing) => (billing.id === selectedBillingCategory)) 
        billing && setcustomsAmount(billing.amount);
        }
      }

    if(!selectedBillingCategory){
      setcustomsAmount(null);
    }
  }, [selectedBillingCategory, pricing, billings, billingsList, editLine]);

  const checkIfBillingsHasStrictAmount = () => {
    let isAmountStrict = true;
   
    // mixed entries
    if(pricing && pricing.customized && pricing.customized.length){
      pricing.customized.find((price) => 
        (price.id === selectedBillingCategory) ? 
          (!price.amount) ? isAmountStrict = false : null : null)
    
    }else{
      billings?.find((billing) => 
        (billing.id === selectedBillingCategory) ? 
          (!billing.amount) ? 
            isAmountStrict = false : null : null)
      }
      
    return isAmountStrict;
  };

  const formatPrice = (value) => {
    return (+'0.00'+(value)).toFixed(2)
  }
 
  const getTableTotal = (vatFree?: string) => {
    let total = 0;
    data.forEach((line) => {
      
      total += line.customsAmount * line.quantity;
    });

    if (!vatFree && countryVatRate) {
      total = total + (total * countryVatRate) / 100;
    }
    return (+'0.00' + total).toFixed(2);
  };

  const removeLine = (billingId) => {
    dispatch(
      actions.customsClerk.removeInvoicingLine({
        params: {
          fileId: match.params.fileId,
          customsClerkBilling: billingId,
        },
      })
    );
  };

  const billingLineSchema = () =>
    Yup.object().shape({
      billing: Yup.string().required('Required'),
      customsAmount: Yup.number().required('Required'),
      quantity: Yup.number().required('Required'),
    });

  const initializeEditValues = (setFieldValue, category, amount, quantity) => {
    setselectedBillingCategory(category);
    setcustomsAmount(amount);
    seteditQuantity(quantity);
    setFieldValue('billing', category);
    setFieldValue('customsAmount', amount);
    setFieldValue('quantity', quantity);
    seteditLine(true);
  };

  const submit = (values, formik) => {
    if (!editLine) {
      dispatch(
        actions.customsClerk.addInvoicingLine({
          params: {
            fileId: match.params.fileId,
            customsClerkBilling: values.billing,
          },
          data: {
            customsAmount: values.customsAmount,
            quantity: values.quantity,
          },
        })
      );
    } else {
      dispatch(
        actions.customsClerk.updateInvoicingLine({
          params: {
            fileId: match.params.fileId,
            customsClerkBilling: values.billing,
          },
          data: {
            customsAmount: String(values.customsAmount),
            quantity: String(values.quantity),
          },
        })
      );
      seteditLine(false);
    }

    setselectedBillingCategory(null);
    setcustomsAmount(null);
    formik.resetForm();
  };

  return (
    <Wrapper>
      <Formik
        initialValues={{
          billing: selectedBillingCategory || '',
          customsAmount: customsAmount || 0,
          quantity: editQuantity || 0,
        }}
        onSubmit={submit}
        validationSchema={billingLineSchema}
        ref={formikRef}
        enableReinitialize
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          resetForm,
        }: any) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Wrapper {...(isFileValidated && { display: 'none' })}>
                <Modal
                  open={editLine}
                  onClose={() => {
                    seteditLine(false);
                    setselectedBillingCategory(null);
                    setcustomsAmount(null);
                    seteditQuantity(null);
                    resetForm();
                  }}
                  closeIcon
                >
                  <Wrapper
                    display='flex'
                    justifyContent='space-around'
                    padding='3rem'
                  >
                    <Wrapper width='100%' margin='0 0.5rem 0 0'>
                      <Field
                        disabled={editLine}
                        mandatory
                        label='Billing'
                        placeholder='Choose a billing'
                        name='billing'
                        options={billingsList}
                        component={Dropdown}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                        onChange={(value) => {
                          setselectedBillingCategory(value);
                        }}
                      />
                    </Wrapper>
                    <Wrapper
                      display='flex'
                      justifyContent='space-around'
                      alignItems='flex-end'
                    >
                      <Wrapper margin='0 0.5rem 0 0'>
                        <Field
                          mandatory
                          disabled={checkIfBillingsHasStrictAmount()}
                          label='Amount (€)'
                          placeholder='0'
                          name='customsAmount'
                          type='number'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Wrapper>
                      <Wrapper margin='0 0.5rem 0 0'>
                        <Field
                          mandatory
                          label='Quantity (u)'
                          placeholder='0'
                          name='quantity'
                          type='number'
                          component={Input}
                          meta={{ errors, touched }}
                          margin='20px'
                        />
                      </Wrapper>
                      <Wrapper>
                        <Button
                          type='button'
                          icon='save'
                          color='blue'
                          display='inline'
                          onClick={() => {
                            handleSubmit();
                          }}
                        />
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
                </Modal>
              </Wrapper>
              <Wrapper {...(isFileValidated && { display: 'none' })}>
                <Wrapper 
                  display='flex' 
                  justifyContent='space-around' 
                  alignItems='flex-start'
                >
                  <Wrapper width='100%' margin='0 0.5rem 0 0'>
                    <Field
                      mandatory
                      label='Billing'
                      placeholder='Choose a billing'
                      name='billing'
                      options={billingsList}
                      loading={pricingLoading}
                      component={Dropdown}
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      onChange={(value) => {
                        setselectedBillingCategory(value);
                      }}
                    />
                  </Wrapper>
                  <Wrapper
                    display='flex'
                    justifyContent='space-around'
                    alignItems='flex-end'
                  >
                    <Wrapper margin='0 0.5rem 0 0'>
                      <Field
                        mandatory
                        disabled={(customsAmount && true) || !selectedBillingCategory}
                        label='Amount (€)'
                        placeholder='0'
                        name='customsAmount'
                        type='number'
                        component={Input}
                        meta={{ errors, touched }}
                        margin='20px'
                      />
                    </Wrapper>

                    <Wrapper margin='0 0.5rem 0 0'>
                      <Field
                        mandatory
                        label='Quantity (u)'
                        placeholder='0'
                        name='quantity'
                        type='number'
                        component={Input}
                        meta={{ errors, touched }}
                        margin='20px'
                      />
                    </Wrapper>
                    <Wrapper>
                      <Button
                        type='submit'
                        {...(addInvoicingLineLoading
                          ? {
                              icon: <Icon loading name='circle notch' />,
                              disabled: true,
                            }
                          : { icon: 'plus' })}
                        color='blue'
                        display='inline'
                      />
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              </Wrapper>
              <Divider hidden />

              <Table columns={5} stackable sortable selectable striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Category</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Quantity</Table.HeaderCell>
                    <Table.HeaderCell>Total (€)</Table.HeaderCell>
                    {!isFileValidated && <Table.HeaderCell />}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {(data && data.length === 0 && (
                    <EmptyTable colNumber={6} />
                  )) ||
                    data.map((e, i) => {
                      return (
                        <Table.Row key={i} className='cursor-pointer'>
                          <Table.Cell>
                            {e.CustomsClerkBilling.category}
                          </Table.Cell>
                          <Table.Cell>
                            {e.CustomsClerkBilling.description}
                          </Table.Cell>
                          <Table.Cell>{formatPrice(e.customsAmount) || '0'}</Table.Cell>
                          <Table.Cell>{e.quantity}</Table.Cell>
                          <Table.Cell>
                          {formatPrice(e.quantity * e.customsAmount)}
                       
                          </Table.Cell>
                          {!isFileValidated && (
                            <Table.Cell>
                              <Wrapper
                                display='flex'
                                justifyContent='space-between'
                              >
                                <Wrapper>
                                  <Button
                                    type='button'
                                    {...(updateInvoicingLineLoading
                                      ? {
                                          icon: (
                                            <Icon loading name='circle notch' />
                                          ),
                                          disabled: true,
                                        }
                                      : { icon: 'edit' })}
                                    color='blue'
                                    display='inline'
                                    onClick={() => {
                                      initializeEditValues(
                                        setFieldValue,
                                        e.CustomsClerkBilling.id,
                                        e.customsAmount,
                                        e.quantity
                                      );
                                    }}
                                  />
                                </Wrapper>
                                <Wrapper>
                                  <Button
                                    type='button'
                                    {...(removeInvoicingLineLoading
                                      ? {
                                          icon: (
                                            <Icon loading name='circle notch' />
                                          ),
                                          disabled: true,
                                        }
                                      : { icon: 'trash' })}
                                    color='red'
                                    display='inline'
                                    onClick={() => {
                                      removeLine(e.CustomsClerkBilling_Id);
                                    }}
                                  />
                                </Wrapper>
                              </Wrapper>
                            </Table.Cell>
                          )}
                        </Table.Row>
                      );
                    })}
                </Table.Body>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell>
                      <TextCustom fontWeight='bold'>TOTAL</TextCustom>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan='5'>
                      {`${getTableTotal('vatFree')} € `}
                      {countryVatRate && `(before tax)`}
                      <br />
                      {countryVatRate &&
                        `${getTableTotal()} € (VAT included ${countryVatRate}%)`}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Form>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    pricing: selectors.companyClerksPricing.getOneClerksPricingSelector(state),
    pricingLoading: selectors.companyClerksPricing.loadingSelector(state),
    billings: selectors.customsClerk.billingsSelector(state),
    billingsLoading: selectors.customsClerk.billingsSelector(state),
    fileHasBillings: selectors.customsClerk.fileHasBillingsSelector(state),
    addInvoicingLineLoading: selectors.customsClerk.addInvoicingLineLoadingSelector(state),
    updateInvoicingLineLoading: selectors.customsClerk.updateInvoicingLineLoadingSelector(state),
    removeInvoicingLineLoading: selectors.customsClerk.removeInvoicingLineLoadingSelector(state),
    file: selectors.customsClerk.oneFileSelector(state),
    countryVatRate: selectors.data.countryVatRateSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(BillingTable);
