const url = process.env.REACT_APP_DEV_API_URL;
const url_api = `${url}/api/v1`;

export default {
  url,
  url_api,
};

export const routesApi = {
  user: {
    login: {
      method: 'POST',
      path: '/user/login',
    },
    create: {
      method: 'POST',
      path: '/user/create',
    },
    workmailRenewal: {
      method: 'POST',
      path: '/user/workmail',
    },
    resetPassword: {
      method: 'POST',
      path: '/user/resetPassword',
    },
    validate: {
      method: 'GET',
      path: '/user/validate/:token',
    },
    logout: {
      method: 'GET',
      path: '/user/logout',
    },
    getAll: {
      method: 'GET',
      path: '/user/getAll',
    },
    remove: {
      method: 'DELETE',
      path: '/user/:userId',
    },
    getOne: {
      method: 'GET',
      path: '/user/:userId',
    },
    update: {
      method: 'PATCH',
      path: '/user/:userId',
    },
    updatePassword: {
      method: 'PATCH',
      path: '/user/:userId/updatePassword',
    },
    updatePasswordWhithToken: {
      method: 'PATCH',
      path: '/user/token/:token/updatePassword',
    },
    switchUser: {
      method: 'GET',
      path: '/user/superAdmin/:userEmail/view',
    },
    removeCookies: {
      method: 'POST',
      path: '/user/clearCookies',
    },
  },
  configuration: {
    monitoring: {
      getThreshold: {
        method: 'GET',
        path: '/configurations/monitoring/getThreshold',
      },
      createThreshold: {
        method: 'POST',
        path: '/configurations/monitoring/createThreshold',
      },
      updateThreshold: {
        method: 'PATCH',
        path: '/configurations/monitoring/updateThreshold',
      },
      deleteThreshold: {
        method: 'DELETE',
        path: '/configurations/monitoring/deleteThreshold',
      },
    },
  },
  fiscal: {
    collectionbox: {
      obligations: {
        getObligation: {
          method: 'GET',
          path: '/fiscal/CollectionBox/:companyId/obligations'
        },
        createObligation: {
          method: 'POST',
          path: '/fiscal/CollectionBox/:companyId/obligations/create'
        },
      },
      companiesByIso: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/companySubscribed/:iso',
      },
      subscribed: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/subscribedContacts',
      },
      categories: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/categories',
      },
      readChat: {
        method: 'PATCH',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/readChat',
      },
      companySubscribed: {
        method: 'GET',
        path: '/fiscal/CollectionBox/companySubscribed',
      },
      subscribe: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/subscribe',
      },
      unsubscribe: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/unsubscribe',
      },
      settings: {
        method: 'PATCH',
        path: '/fiscal/CollectionBox/:companyId/update/settings',
      },
      getSettings: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/get/settings',
      },
      followCollectionbox: {
        method: 'PATCH',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/follow',
      },
      updateStep: {
        method: 'PATCH',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/updateStep',
      },
      docsFromCat: {
        method: 'GET',
        path: '/fiscal/CollectionBox/getDocsFromCat',
      },
      deleteDoc: {
        method: 'DELETE',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/deleteDocuments/:documentId',
      },
      getDocument: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/getDocument/:documentId',
      },
      updateDocument: {
        method: 'PATCH',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/:documentId/:validation',
      },
      getByFilters: {
        method: 'GET',
        path: '/fiscal/CollectionBox/declaration/getByFilters',
      },
      getAllCollectionboxes: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/allCollectionboxes',
      },
      getOneCollectionbox: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId',
      },
      updateCollectionbox: {
        method: 'PATCH',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/update',
      },
      createNoneCollectionbox: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/none/create',
      },
      createCollectionbox: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/create',
      },
      tagOperations: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/tag/:documentId',
      },
      addDocuments: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/addDocuments',
      },
      getFilesFullList: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/files/:collectionboxId/:documentId/:folder/:status',
      },
      getArchiveFile: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/tmp/:session/:documentName',
      },
      singlefile: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/singlefile',
      },
      split: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/splitDocument/:documentId',
      },
      groupFiles: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/groupFiles/:documentId',
      },
      deleteFiles: {
        method: 'DELETE',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/deleteFiles/:folder',
      },
      changeFileState: {
        method: 'PATCH',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/changeFileState/:folder/:state',
      },
      renameSendChunk: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/renameSendChunk/:documentId',
      },
      filterTag: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/filterTag/:selectedTag/:documentId',
      },
      filterTagArchive: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/filterTagArchive/:selectedTag/:documentId/:category',
      },
      archiveAll: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/archive/:documentId',
      },
      bulkFilter: {
        method: 'POST',
        path: '/fiscal/CollectionBox/:companyId/:collectionboxId/bulkFilter/:selectedTag/and/:category',
      },
      perms: {
        method: 'GET',
        path: '/fiscal/CollectionBox/:companyId/permissions/:operatorId',
      },
    },
    VATdeclaration: {
      companySubscribed: {
        method: 'GET',
        path: '/fiscal/VATdeclaration/companySubscribed',
      },
      subscribe: {
        method: 'GET',
        path: '/fiscal/VATdeclaration/:companyId/subscribe',
      },
      unsubscribe: {
        method: 'GET',
        path: '/fiscal/VATdeclaration/:companyId/unsubscribe',
      },
      getMapping: {
        method: 'GET',
        path: '/fiscal/VATdeclaration/mapping',
      },
      getTA: {
        method: 'GET',
        path: '/fiscal/VATdeclaration/:asdId/getTA',
      },
      declare: {
        method: 'POST',
        path: '/fiscal/VATdeclaration/:companyId/:companyName',
      },
      getDeclarations: {
        method: 'GET',
        path: '/fiscal/VATdeclaration/',
      },
      getDeclaration: {
        method: 'GET',
        path: '/fiscal/VATdeclaration/:companyName/:declarationId',
      },
      getLines: {
        method: 'GET',
        path: '/fiscal/VATdeclaration/:companyName/:declarationId/details',
      },
      validate: {
        method: 'POST',
        path: '/fiscal/VATdeclaration/validate/:companyName/:declarationId',
      },
      remove: {
        method: 'DELETE',
        path: '/fiscal/VATdeclaration/:companyName/:declarationId',
      },
    },
    fiscalVat: {
      categories: {
        method: 'GET',
        path: '/fiscal/TaxFlow/:companyId/categories',
      },
      archivesFiles: {
        method: 'GET',
        path: '/fiscal/TaxFlow/:companyId/:declarationId/fullList/:repository',
      },
      assigCountries: {
        method: 'GET',
        path: '/fiscal/TaxFlow/:operatorId/countries',
      },
      updateDocument: {
        method: 'PATCH',
        path: '/fiscal/TaxFlow/:companyId/:declarationId/:docId/:validation',
      },
      mapping: {
        method: 'GET',
        path: '/fiscal/TaxFlow/mapping',
      },
      subscribed: {
        method: 'GET',
        path: '/fiscal/TaxFlow/subscribed',
      },
      unsubscribe: {
        method: 'GET',
        path: '/fiscal/TaxFlow/:companyId/unsubscribe',
      },
      subscribe: {
        method: 'GET',
        path: '/fiscal/TaxFlow/:companyId/subscribe',
      },
      followVatDeclaration: {
        method: 'PATCH',
        path: '/fiscal/TaxFlow/:companyId/:declarationId/follow',
      },
      updateStep: {
        method: 'PATCH',
        path: '/fiscal/TaxFlow/:companyId/:declarationId/updateStep',
      },
      docsFromCat: {
        method: 'GET',
        path: '/fiscal/TaxFlow/getDocsFromCat',
      },
      deleteDoc: {
        method: 'DELETE',
        path: '/fiscal/TaxFlow/:companyId/:declarationId/deleteDocuments/:documentId',
      },
      getDocument: {
        method: 'GET',
        path: '/fiscal/TaxFlow/:companyId/:declarationId/getDocument/:documentId',
      },
      getAllVatDeclarations: {
        method: 'GET',
        path: '/fiscal/TaxFlow/vatDeclarations',
      },
      getAllCompanyVatDeclarations: {
        method: 'GET',
        path: '/fiscal/TaxFlow/:companyId/allVatDeclarations',
      },
      getAllCountryVatDeclarations: {
        method: 'GET',
        path: '/fiscal/TaxFlow/country/:country',
      },
      getVatDeclaration: {
        method: 'GET',
        path: '/fiscal/TaxFlow/:companyId/:declarationId',
      },
      updateVatDeclaration: {
        method: 'PATCH',
        path: '/fiscal/TaxFlow/:companyId/:declarationId/update',
      },
      createVatDeclaration: {
        method: 'POST',
        path: '/fiscal/TaxFlow/:companyId/create',
      },
      addDocuments: {
        method: 'POST',
        path: '/fiscal/TaxFlow/:companyId/:declarationId/addDocuments',
      },
      deleteFiles: {
        method: 'DELETE',
        path: '/fiscal/TaxFlow/:companyId/:declarationId/deleteFiles/:folder',
      },
      obligations: {
        getObligation: {
          method: 'GET',
          path: '/fiscal/TaxFlow/:companyId/obligations/:countryId',
        },
        createObligation: {
          method: 'POST',
          path: '/fiscal/TaxFlow/:companyId/obligations/create',
        },
      },
      excel: {
        getDocument: {
          method: 'GET',
          path: '/fiscal/TaxFlow/:companyId/excel/:excelId',
        },
        update: {
          method: 'POST',
          path: '/fiscal/TaxFlow/:companyId/excel/:excelId/update'
        }
      }
    },
    OssIntegration: {
      companySubscribed: {
        method: 'GET',
        path: '/fiscal/oss/companySubscribed',
      },
      subscribe: {
        method: 'GET',
        path: '/fiscal/oss/:companyId/subscribe',
      },
      unsubscribe: {
        method: 'GET',
        path: '/fiscal/oss/:companyId/unsubscribe',
      },
      getIntegrations: {
        method: 'GET',
        path: '/fiscal/oss/',
      },
      registerIntegration: {
        method: 'POST',
        path: '/fiscal/oss/:companyId/registerIntegration',
      },
      getCF: {
        method: 'GET',
        path: '/fiscal/oss/:companyId/getCF',
      },
    },
    POD: {
      token: {
        getDeliveryByToken: {
          method: 'GET',
          path: '/fiscal/kyanite/token/:token',
        },
        transporterSelection: {
          method: 'POST',
          path: '/fiscal/kyanite/token/:token/transporter',
        },
        uploadProof: {
          method: 'POST',
          path: '/fiscal/kyanite/token/:token/uploadProof',
        },
        getOneProof: {
          method: 'GET',
          path: '/fiscal/kyanite/token/:token/downloadProof/:proofId',
        },
        downloadAttestation: {
          method: 'GET',
          path: '/fiscal/kyanite/token/:token/downloadAttestation',
        },
        downloadCustomsRegimeInvoice: {
          method: 'GET',
          path: '/fiscal/kyanite/token/:token/downloadCustomsRegimeInvoice',
        },
      },
      getPrefilledDelivery: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/prefilled/:customsId',
      },
      checkQuickproofExist: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/checkQuickproofExist/:customsId',
      },
      setVatNumber: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/:deliveryId/setVat/:vat',
      },
      hasPaidSubscription: {
        method: 'GET',
        path: '/fiscal/kyanite/haspaidSubscription/:companyId/:serviceName',
      },
      getCompanySubscribed: {
        method: 'GET',
        path: '/fiscal/kyanite/companySubscribed',
      },
      subscribe: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/subscribe',
      },
      subscribeWithCode: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/subscribe',
      },
      contact: {
        method: 'POST',
        path: '/fiscal/kyanite/contact',
      },
      unsubscribe: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/unsubscribe',
      },
      getAll: {
        method: 'GET',
        path: '/fiscal/kyanite/',
      },
      downloadAttestation: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/:deliveryId/downloadAttestation',
      },
      downloadCustomsRegimeInvoice: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/:deliveryId/downloadCustomsRegimeInvoice',
      },
      askForProof: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/:deliveryId/askForProof',
      },
      getOne: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/:deliveryId/:type',
      },
      getPODOfOneTransporter: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/transporter',
      },
      getPODOfOneBuyer: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/buyer',
      },
      getPODOfOneSeller: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/seller',
      },
      getPODOfOneSellerByFilters: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/seller/byFilters',
      },
      create: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId',
      },
      update: {
        // edit delivery
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/:deliveryId/update',
      },
      getTotalVatRisk: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/VatRisk',
      },
      getNumberOfReceived: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/numberOfDeliveriesReceived',
      },
      getNumberOfPending: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/numberOfDeliveriesPending',
      },
      getNumberOfProofPending: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/numberOfProofPending',
      },
      uploadProof: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/:deliveryId/uploadProof',
      },
      uploadSplitProof: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/:deliveryId/uploadSplitProof',
      },
      downloadAllProof: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/:deliveryId/downloadProof',
      },
      downloadOneProof: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/:deliveryId/downloadProof/:proofId',
      },
      getVatRisk: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/vatRisk/calculate',
      },
      getCDIDs: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/getCDIDs',
      },
      searchForClientRef: {
        method: 'GET',
        path: '/fiscal/kyanite/searchForClientRef',
      },
      validateProof: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/:deliveryId/:proofId/validate',
      },
      refuseProof: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/:deliveryId/:proofId/refuse',
      },
      validateDelivery: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/:deliveryId/validate',
      },
      getOneForBuyer: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/:deliveryId/buyer',
      },
      getOneForTransporter: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/:deliveryId/transporter',
      },
      getMassIntegrations: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/massIntegrations',
      },
      downloadMassIntegrationTemplate: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/massIntegrations/downloadTemplate',
      },
      getOneMassIntegration: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/massIntegration/:massIntegrationId',
      },
      createMassIntegration: {
        method: 'POST',
        path: '/fiscal/kyanite/:companyId/massIntegration',
      },
      deleteMassIntegration: {
        method: 'DELETE',
        path: '/fiscal/kyanite/:companyId/:massIntegrationId/massIntegration',
      },
      getBuyersTransporters: {
        method: 'GET',
        path: '/fiscal/kyanite/:companyId/BuyersTransporters',
      },
    },
  },
  status: {
    allStatus: {
      method: 'GET',
      path: '/status/allStatus',
    },
  },
  data: {
    allCountries: {
      method: 'GET',
      path: '/data/allCountries',
    },
    euCountries: {
      method: 'GET',
      path: '/data/euCountries',
    },
    countryVatRate: {
      method: 'POST',
      path: '/data/countryVatRate',
    },
  },
  intrastat: {
    companySubscribed: {
      method: 'GET',
      path: '/intrastat/companySubscribed',
    },
    subscribe: {
      method: 'GET',
      path: '/intrastat/:companyId/subscribe',
    },
    unsubscribe: {
      method: 'GET',
      path: '/intrastat/:companyId/unsubscribe',
    },
    getCategories: {
      method: 'GET',
      path: '/intrastat/:companyId/getCategories',
    },
    settings: {
      method: 'PATCH',
      path: '/intrastat/:companyId/update/settings',
    },
    getSettings: {
      method: 'GET',
      path: '/intrastat/:companyId/get/settings',
    },
    followIntrastat: {
      method: 'PATCH',
      path: '/intrastat/:companyId/:intrastatId/follow',
    },
    updateStep: {
      method: 'PATCH',
      path: '/intrastat/:companyId/:intrastatId/updateStep',
    },
    docsFromCat: {
      method: 'GET',
      path: '/intrastat/getDocsFromCat',
    },
    deleteDoc: {
      method: 'DELETE',
      path: '/intrastat/:companyId/:intrastatId/deleteDocuments/:documentId',
    },
    getDocument: {
      method: 'GET',
      path: '/intrastat/:companyId/:intrastatId/getDocument/:documentId',
    },
    updateDocument: {
      method: 'PATCH',
      path: '/intrastat/:companyId/:intrastatId/:documentId/:validation',
    },
    getByFilters: {
      method: 'GET',
      path: '/intrastat/declaration/getByFilters',
    },
    getAllIntrastats: {
      method: 'GET',
      path: '/intrastat/:companyId/allIntrastats',
    },
    getOneIntrastat: {
      method: 'GET',
      path: '/intrastat/:companyId/:intrastatId',
    },
    updateIntrastat: {
      method: 'PATCH',
      path: '/intrastat/:companyId/:intrastatId/update',
    },
    createNoneIntrastat: {
      method: 'POST',
      path: '/intrastat/:companyId/none/create',
    },
    createIntrastat: {
      method: 'POST',
      path: '/intrastat/:companyId/create',
    },
    tagOperations: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/tag/:documentId',
    },
    addDocuments: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/addDocuments',
    },
    getFilesFullList: {
      method: 'GET',
      path: '/intrastat/:companyId/files/:intrastatId/:documentId/:folder/:status',
    },
    getArchiveFile: {
      method: 'GET',
      path: '/intrastat/:companyId/tmp/:session/:documentName',
    },
    singlefile: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/singlefile',
    },
    split: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/splitDocument/:documentId',
    },
    groupFiles: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/groupFiles/:documentId',
    },
    deleteFiles: {
      method: 'DELETE',
      path: '/intrastat/:companyId/:intrastatId/deleteFiles/:folder',
    },
    changeFileState: {
      method: 'PATCH',
      path: '/intrastat/:companyId/:intrastatId/changeFileState/:folder/:state',
    },
    renameSendChunk: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/renameSendChunk/:documentId',
    },
    filterTag: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/filterTag/:selectedTag/:documentId',
    },
    filterTagArchive: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/filterTagArchive/:selectedTag/:documentId/:category',
    },
    archiveAll: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/archive/:documentId',
    },
    bulkFilter: {
      method: 'POST',
      path: '/intrastat/:companyId/:intrastatId/bulkFilter/:selectedTag/and/:category',
    },
    perms: {
      method: 'GET',
      path: '/intrastat/:companyId/permissions/:operatorId',
    },
  },
  chat: {
    getAllMsgs: {
      method: 'GET',
      path: '/chat/:companyId/:id/get',
    },
    postMsgs: {
      method: 'POST',
      path: '/chat/:companyId/:id/post',
    },
    readChat: {
      method: 'PATCH',
      path: '/chat/:companyId/:declarationId/readChat',
    },
  },
  notification: {
    allNotification: {
      method: 'GET',
      path: '/notification/',
    },
    notificationUnRead: {
      method: 'GET',
      path: '/notification/unread/',
    },
    notificationIsRead: {
      method: 'POST',
      path: '/notification/updateOne/:notificationId/',
    },
    notificationAllRead: {
      method: 'POST',
      path: '/notification/updateAll/',
    },
    notificationIsShow: {
      method: 'DELETE',
      path: '/notification/deleteOne/:notificationId',
    },
    notificationAllDelete: {
      method: 'DELETE',
      path: '/notification/deleteAll/:userId/',
    },
  },
  company: {
    intrastatSetting: {
      getCompanySetting: {
        method: 'GET',
        path: '/company/:companyId/intrastatSettings/getOperator'
      },
      createSetting: {
        method: 'POST',
        path: '/company/:companyId/intrastatSettings/addOperator'
      },
      updateSetting: {
        method: 'PATCH',
        path: '/company/:companyId/intrastatSettings/updateOperator'
      },
      deleteSetting: {
        method: 'DELETE',
        path: '/company/:companyId/intrastatSettings/deleteOperator'
      },
      getAssignedCountries: {
        method: 'GET',
        path: '/company/:companyId/intrastatSettings/getAssignedCountries'
      },
      updateAssignCountry: {
        method: 'PATCH',
        path: '/company/:companyId/intrastatSettings/updateAssignedCountry'
      },
    },
    taxflowSetting: {
      getAssignedCountries: {
        method: 'GET',
        path: '/company/:companyId/taxflowSettings/getAssignedCountries'
      },
      updateAssignCountry: {
        method: 'PATCH',
        path: '/company/:companyId/taxflowSettings/updateAssignedCountry'
      },
    },
    collectionboxSetting: {
      getCompanySetting: {
        method: 'GET',
        path: '/company/:companyId/collectionboxSettings/getOperator'
      },
      createSetting: {
        method: 'POST',
        path: '/company/:companyId/collectionboxSettings/addOperator'
      },
      updateSetting: {
        method: 'PATCH',
        path: '/company/:companyId/collectionboxSettings/updateOperator'
      },
      deleteSetting: {
        method: 'DELETE',
        path: '/company/:companyId/collectionboxSettings/deleteOperator'
      },
      getAssignedCountries: {
        method: 'GET',
        path: '/company/:companyId/collectionboxSettings/getAssignedCountries'
      },
      updateAssignCountry: {
        method: 'PATCH',
        path: '/company/:companyId/collectionboxSettings/updateAssignedCountry'
      },
    },
    transit: {
      getThreshold: {
        method: 'GET',
        path: '/company/:companyId/transit/',
      },
      createThreshold: {
        method: 'POST',
        path: '/company/:companyId/transit/createThreshold',
      },
      updateThreshold: {
        method: 'PATCH',
        path: '/company/:companyId/transit/updateThreshold',
      },
      deleteThreshold: {
        method: 'DELETE',
        path: '/company/:companyId/transit/deleteThreshold',
      },
    },
    payment: {
      getPayment: {
        method: 'GET',
        path: '/company/:companyId/payment/',
      },
      removePayment: {
        method: 'DELETE',
        path: '/company/:companyId/payment/',
      },
    },
    invoice: {
      getUnpaid: {
        method: 'GET',
        path: '/company/:companyId/invoice/get/unpaid',
      },
      getAll: {
        method: 'GET',
        path: '/company/:companyId/invoice/',
      },
      getOne: {
        method: 'GET',
        path: '/company/:companyId/invoice/:invoiceId',
      },
      payInvoice: {
        method: 'GET',
        path: '/company/:companyId/invoice/:invoiceId/pay',
      },
      createCredit: {
        method: 'POST',
        path: '/company/:companyId/invoice/:invoiceId/credit',
      },
    },
    vat: {
      createVat: {
        method: 'POST',
        path: '/company/:companyId/vat/',
      },
      getAllVat: {
        method: 'GET',
        path: '/company/:companyId/vat/',
      },
      getOneVat: {
        method: 'GET',
        path: '/company/:companyId/vat/:vatId',
      },
      updateVat: {
        method: 'PATCH',
        path: '/company/:companyId/vat/:vatId',
      },
      deleteVat: {
        method: 'DELETE',
        path: '/company/:companyId/vat/:vatId',
      },
    },
    clerksPricing: {
      createClerksPricing: {
        method: 'POST',
        path: '/company/:companyId/billing/create',
      },
      getOneClerksPricing: {
        method: 'GET',
        path: '/company/:companyId/billing/one',
      },
      updateClerksPricing: {
        method: 'PATCH',
        path: '/company/:companyId/billing/update',
      },
      deleteClerksPricing: {
        method: 'DELETE',
        path: '/company/:companyId/billing/delete',
      },
    },
    address: {
      createAddress: {
        method: 'POST',
        path: '/company/:companyId/address/',
      },
      getAllAddresses: {
        method: 'GET',
        path: '/company/:companyId/address/',
      },
      getOneAddress: {
        method: 'GET',
        path: '/company/:companyId/address/:addressId',
      },
      updateAddress: {
        method: 'PATCH',
        path: '/company/:companyId/address/:addressId',
      },
      deleteAddress: {
        method: 'DELETE',
        path: '/company/:companyId/address/:addressId',
      },
    },
    token: {
      getAllTransportersByToken: {
        method: 'GET',
        path: '/company/token/:token/transporters',
      },
      createTransporterByToken: {
        method: 'POST',
        path: '/company/token/:token/transporter',
      },
      getTransporterVats: {
        method: 'GET',
        path: '/company/token/:token/transporterVats/:transporterId',
      },
    },
    user: {
      getUserCompanies: {
        method: 'GET',
        path: '/company/:userId/getUserCompanies',
      },
      addUser: {
        method: 'POST',
        path: '/company/:companyId/user/',
      },
      removeUser: {
        method: 'DELETE',
        path: '/company/:companyId/user/:userId',
      },
      updateUser: {
        method: 'PATCH',
        path: '/company/:companyId/user/:userId',
      },
      updateUserPermissions: {
        method: 'PATCH',
        path: '/company/:companyId/user/:userId/permissions',
      },
      getUserPermissions: {
        method: 'GET',
        path: '/company/:companyId/user/:userId/permissions',
      },
      getOneUser: {
        method: 'GET',
        path: '/company/:companyId/user/:userId',
      },
      getNewUser: {
        method: 'GET',
        path: '/company/user',
      },
      getAllUsers: {
        method: 'GET',
        path: '/company/:companyId/user/',
      },
    },
    driver: {
      getDrivers: {
        method: 'GET',
        path: '/company/:companyId/drivers',
      },
      addDriver: {
        method: 'POST',
        path: '/company/:companyId/driver/',
      },
      removeDriver: {
        method: 'DELETE',
        path: '/company/:companyId/driver/:driverId',
      },
      updateDriver: {
        method: 'PATCH',
        path: '/company/:companyId/driver/:driverId',
      },
      updateDriverPermissions: {
        method: 'PATCH',
        path: '/company/:companyId/driver/:userId/permissions',
      },
      getDriverPermissions: {
        method: 'GET',
        path: '/company/:companyId/driver/:userId/permissions',
      },
      getOneDriver: {
        method: 'GET',
        path: '/company/:companyId/driver/:driverId',
      },
      addDriverToCompany: {
        method: 'POST',
        path: '/company/:companyId/driver/:uniqueId/:pseudo',
      },
      getNewDriver: {
        method: 'GET',
        path: '/company/driver',
      },
      getAllDrivers: {
        method: 'GET',
        path: '/company/:companyId/driver/',
      },
    },
    keyinfo: {
      getKeyinfos: {
        method: 'GET',
        path: '/company/:companyId/keyinfo/',
      },
      keyInfoSetDone: {
        method: 'POST',
        path: '/company/:companyId/keyinfo/done',
      },
      checkKeyInfoSetDone: {
        method: 'GET',
        path: '/company/:companyId/keyinfo/:customsId/check/:emitter',
      },
      updateOneKeyinfo: {
        method: 'PATCH',
        path: '/company/:companyId/keyinfo/:id',
      },
      addKeyinfo: {
        method: 'POST',
        path: '/company/:companyId/keyinfo/',
      },
      removeKeyinfo: {
        method: 'DELETE',
        path: '/company/:companyId/keyinfo/:id',
      },
    },
    contact: {
      getAllContacts: {
        method: 'GET',
        path: '/company/:companyId/contact/',
      },
      getOneContact: {
        method: 'GET',
        path: '/company/:companyId/contact/:contactId',
      },
      getBothContact: {
        method: 'GET',
        path: '/company/:companyId/contact/:contactId/both',
      },
      createContact: {
        method: 'POST',
        path: '/company/:companyId/contact/',
      },
      addContact: {
        method: 'POST',
        path: '/company/:companyId/contact/add/:contactId',
      },
      addContactPartner: {
        method: 'POST',
        path: '/company/:companyId/contact/add/:uniqueNumber',
      },
      addContactRights: {
        method: 'PATCH',
        path: '/company/:companyId/contact/add/:contactId/rights',
      },
      getContactRights: {
        method: 'GET',
        path: '/company/:companyId/contact/get/:contactId/rights',
      },
      getAllContactRights: {
        method: 'GET',
        path: '/company/:companyId/contact/getRights',
      },
      deleteContact: {
        method: 'DELETE',
        path: '/company/:companyId/contact/:contactId',
      },
      validateContact: {
        method: 'GET',
        path: '/company/:companyId/contact/validate/:contactId',
      },
      refuseContact: {
        method: 'GET',
        path: '/company/:companyId/contact/refuse/:contactId',
      },
      getCompaniesNotInContact: {
        method: 'GET',
        path: '/company/:companyId/contact/companiesNotInContact',
      },
      getPendingContact: {
        method: 'GET',
        path: '/company/:companyId/contact/pendingContact',
      },
      getRequestContact: {
        method: 'GET',
        path: '/company/:companyId/contact/requestContact',
      },
      getAllContactTransporters: {
        method: 'GET',
        path: '/company/:companyId/contact/transporters',
      },
      validateContactPartner: {
        method: 'POST',
        path: '/company/token/:token/partnerValidation',
      },
    },
    hasSubscribedTo: {
      method: 'GET',
      path: '/company/:companyId/hasSubscribedTo/:serviceName',
    },
    getAllTransporter: {
      method: 'GET',
      path: '/company/transporters/',
    },
    create: {
      method: 'POST',
      path: '/company/',
    },
    update: {
      method: 'PATCH',
      path: '/company/:companyId',
    },
    remove: {
      method: 'DELETE',
      path: '/company/:companyId',
    },
    getPermissions: {
      method: 'GET',
      path: '/company/:companyId/permissions',
    },
    getOne: {
      method: 'GET',
      path: '/company/:companyId',
    },
    getAll: {
      method: 'GET',
      path: '/company/',
    },
  },
  agency: {
    create: {
      method: 'POST',
      path: '/agency/create',
    },
    getOne: {
      method: 'GET',
      path: '/agency/:agencyId',
    },
    getAll: {
      method: 'GET',
      path: '/agency/',
    },
    remove: {
      method: 'DELETE',
      path: '/agency/:agencyId',
    },
    update: {
      method: 'PATCH',
      path: '/agency/:agencyId',
    },
  },
  service: {
    create: {
      method: 'POST',
      path: '/service/create',
    },
    getOne: {
      method: 'GET',
      path: '/service/:serviceId',
    },
    update: {
      method: 'PATCH',
      path: '/service/:serviceId',
    },
    getAll: {
      method: 'GET',
      path: '/service/',
    },
    remove: {
      method: 'DELETE',
      path: '/service/:serviceId',
    },
    createProduct: {
      method: 'POST',
      path: '/service/product/create/:serviceId',
    },
    getProduct: {
      method: 'GET',
      path: '/service/product/:productRef',
    },
    getProductsOfService: {
      method: 'GET',
      path: '/service/product/:serviceId/getProducts',
    },
    getProductsOfServiceSub: {
      method: 'GET',
      path: '/service/product/:serviceName/getProductsSub',
    },
    removeProduct: {
      method: 'DELETE',
      path: '/service/product/:productRef',
    },
    updateProduct: {
      method: 'PATCH',
      path: '/service/product/:productRef',
    },
  },
  customs: {
    statistics: {
      getComputedCheds: {
        method: 'GET',
        path: '/customs/statistics/ched/:globalStatistics',
      },
      getDeclarationsStatsWithFilters: {
        method: 'POST',
        path: '/customs/statistics/byFilters',
      },
      getCustomsClerkStats: {
        method: 'POST',
        path: '/customs/statistics/validatedClerks',
      },
      getLastCustoms: {
        method: 'POST',
        path: '/customs/statistics/getLastCustoms/:period',
      },
      getDeclarationForComparison: {
        method: 'POST',
        path: '/customs/statistics/compareYears',
      },
    },
    declaration: {
      transitRisk: {
        method: 'GET',
        path: '/customs/declaration/:companyId/transitsInProgress',
      },
      transitRates: {
        method: 'GET',
        path: '/customs/declaration/:companyId/transitRates/:type',
      },
      getAccises: {
        method: 'GET',
        path: '/customs/declaration/:companyId/Accises',
      },
      getAllAccises: {
        method: 'GET',
        path: '/customs/declaration/:companyId/allAccises',
      },
      queueSendNotif: {
        method: 'POST',
        path: '/customs/declaration/:companyId/notificationType/:notificationType/:uniqueId',
      },
      deleteConsignment: {
        method: 'DELETE',
        path: '/customs/declaration/:companyId/:declarationId/ched/deleteConsignment/:consignmentId',
      },
      createIntrastat: {
        method: 'POST',
        path: '/customs/declaration/declare/:companyId/createIntrastat',
      },
      updateIntrastat: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/updateIntrastat',
      },
      createTransit: {
        method: 'POST',
        path: '/customs/declaration/declare/:companyId/createTransit',
      },
      updateTransit: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/updateTransit',
      },
      createAccises: {
        method: 'POST',
        path: '/customs/declaration/declare/:companyId/createAccises',
      },
      updateAccises: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/updateAccises',
      },
      addConsignment: {
        method: 'POST',
        path: '/customs/declaration/:companyId/:declarationId/ched/:chedId/addConsignment',
      },
      updateConsignment: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/ched/:chedId/updateConsignment',
      },
      readChat: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/readChat',
      },
      getDeclarationInvoice: {
        method: 'GET',
        path: '/customs/declaration/invoice',
      },
      getParking: {
        method: 'GET',
        path: '/customs/declaration/parking',
      },
      getDeclarationInvoiceByFilters: {
        method: 'POST',
        path: '/customs/declaration/invoice/byFilters',
      },
      updateDeclarationInvoice: {
        method: 'PATCH',
        path: '/customs/declaration/validateInvoice/:declarationId',
      },
      addIntranetReference: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/addIntranetReference',
      },
      companySubscribed: {
        method: 'GET',
        path: '/customs/declaration/companySubscribed',
      },
      subscribe: {
        method: 'GET',
        path: '/customs/declaration/:companyId/subscribe',
      },
      updateDeclaration: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/updateData',
      },
      updateStep: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/updateStep',
      },
      addStep: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/addStep',
      },
      unsubscribe: {
        method: 'GET',
        path: '/customs/declaration/:companyId/unsubscribe',
      },
      getAllCustomsOffice: {
        method: 'GET',
        path: '/customs/declaration/get/customsOffice',
      },
      getProcessingDeclarations: {
        method: 'GET',
        path: '/customs/declaration/',
      },
      getProcessingDeclarationsByFilters: {
        method: 'POST',
        path: '/customs/declaration/byFilters',
      },
      getOne: {
        method: 'GET',
        path: '/customs/declaration/get/:companyId/:declarationId',
      },
      getOneHosted: {
        method: 'GET',
        path: '/customs/declaration/get/:declarationId/:companyId/hosted',
      },
      downloadAllDocuments: {
        method: 'POST',
        path: '/customs/declaration/post/:companyId/:declarationId/documents',
      },
      getAll: {
        method: 'GET',
        path: '/customs/declaration/get/:companyId',
      },
      getAllHosted: {
        method: 'GET',
        path: '/customs/declaration/get/:companyId/hosted',
      },
      addPartnerComment: {
        method: 'PATCH',
        path: '/customs/declaration/post/:declarationId/:companyId/hosted/comments',
      },
      getPartnerComments: {
        method: 'GET',
        path: '/customs/declaration/get/:declarationId/:companyId/hosted/comments',
      },
      getAllCategoriesDocuments: {
        method: 'GET',
        path: '/customs/declaration/document/categories',
      },
      declare: {
        method: 'POST',
        path: '/customs/declaration/declare/:companyId/declaration',
      },
      addDocuments: {
        method: 'POST',
        path: '/customs/declaration/:companyId/:declarationId/addDocuments',
      },
      deleteDocument: {
        method: 'DELETE',
        path: '/customs/declaration/:companyId/:declarationId/deleteDocument/:documentId',
      },
      downloadDocument: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/getDocument/:documentId',
      },
      extractDocuments: {
        method: 'POST',
        path: '/customs/declaration/extract/:companyId/:from/:to',
      },
      extractDauBae: {
        method: 'POST',
        path: '/customs/declaration/extractDauBae/:from/:to',
      },
      getChat: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/getChat',
      },
      sendMessage: {
        method: 'POST',
        path: '/customs/declaration/:companyId/:declarationId/sendMessage',
      },
      followDeclaration: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/follow',
      },
      unfollowDeclaration: {
        method: 'DELETE',
        path: '/customs/declaration/:companyId/:declarationId/unfollow',
      },
      cancelDeclaration: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/cancel',
      },
      getAllChed: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/ched/',
      },
      getOneChed: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/ched/:chedId',
      },
      addChed: {
        method: 'POST',
        path: '/customs/declaration/:companyId/:declarationId/ched/',
      },
      removeChed: {
        method: 'DELETE',
        path: '/customs/declaration/:companyId/:declarationId/ched/:chedId',
      },
      updateChedData: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/ched/:chedId',
      },
      convertFile: {
        method: 'POST',
        path: '/customs/declaration/convertFile',
      },
      reloadLogisticom: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/reloadLogisticom',
      },
      sendNoticeMail: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/sendNoticeMail',
      },
      getAllTraces: {
        method: 'GET',
        path: '/customs/declaration/fetchAllCompaniesWithTraces',
      },
      addMandate: {
        method: 'POST',
        path: '/customs/declaration/upload/:companyId/mandate',
      },
      getMandates: {
        method: 'GET',
        path: '/customs/declaration/get/:companyId/mandates',
      },
      downloadMandates: {
        method: 'GET',
        path: '/customs/declaration/downloadMandate/:id',
      },
      getConex: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/getConex',
      },
      getChedStats: {
        method: 'GET',
        path: '/customs/declaration/:companyId/statistics/ched',
      },
      getStatsWithFilters: {
        method: 'POST',
        path: '/customs/declaration/:companyId/statistics/byFilters/:globalStatistics',
      },
      addLines: {
        method: 'POST',
        path: '/customs/declaration/:companyId/:declarationId/addLines',
      },
      updateLines: {
        method: 'PATCH',
        path: '/customs/declaration/:companyId/:declarationId/updateLines',
      },
      deleteLines: {
        method: 'DELETE',
        path: '/customs/declaration/:companyId/:declarationId/deleteLines/:id/:fileId',
      },
      getAllLines: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/getAllLines',
      },
      checkConexDelivery: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/checkConex',
      },
      getOneByReference: {
        method: 'GET',
        path: '/customs/declaration/:companyId/:declarationId/byReference',
      },
      getCategories: {
        method: 'GET',
        path: '/customs/declaration/:companyId/getCategories',
      },
      downloadCheds: {
        method: 'POST',
        path: '/customs/declaration/:companyId/:declarationId/ched/download/documents',
      },
    },
    clerk: {
      updateFile: {
        method: 'PATCH',
        path: '/customs/clerk/:fileId',
      },
      getDeclarationInProgress: {
        method: 'GET',
        path: '/customs/clerk/:companyId/declaration',
      },
      getOneFile: {
        method: 'GET',
        path: '/customs/clerk/:fileId',
      },
      getStartSignature: {
        method: 'GET',
        path: '/customs/clerk/:fileId/startSignature',
      },
      getTrailerLicensePlate: {
        method: 'GET',
        path: '/customs/clerk/:fileId/issue/trailerLicensePlate',
      },
      addTrailerLicensePlate: {
        method: 'POST',
        path: '/customs/clerk/:fileId/issue/trailerLicensePlate/',
      },
      removeTrailerLicensePlate: {
        method: 'DELETE',
        path: '/customs/clerk/:fileId/issue/trailerLicensePlate/',
      },
      getPaymentReceipt: {
        method: 'GET',
        path: '/customs/clerk/:fileId/paymentReceipt',
      },
      getPaymentReceiptTimeStamp: {
        method: 'GET',
        path: '/customs/clerk/:fileId/paymentReceipt/timeStamp',
      },
      getEndSignature: {
        method: 'GET',
        path: '/customs/clerk/:fileId/endSignature',
      },
      createFile: {
        method: 'POST',
        path: '/customs/clerk/:companyId',
      },
      downloadPicturesIssuesZIP: {
        method: 'GET',
        path: '/customs/clerk/:fileId/issue/picture/',
      },
      getSummary: {
        method: 'GET',
        path: '/customs/clerk/:fileId/summary',
      },
      getAllFiles: {
        method: 'GET',
        path: '/customs/clerk',
      },
      getAllFilesByFilters: {
        method: 'POST',
        path: '/customs/clerk/byFilters',
      },
      getIssue: {
        method: 'GET',
        path: '/customs/clerk/:fileId/issue/',
      },
      updateIssue: {
        method: 'PATCH',
        path: '/customs/clerk/:fileId/issue/',
      },
      addIssuePictures: {
        method: 'POST',
        path: '/customs/clerk/:fileId/issue/picture/',
      },
      deleteIssuePicture: {
        method: 'DELETE',
        path: '/customs/clerk/:fileId/issue/picture/:issuePictureId',
      },
      getBillings: {
        method: 'GET',
        path: '/customs/clerk/departure/invoicing',
      },
      cdBillings: {
        method: 'GET',
        path: '/customs/clerk/:customsDeclarationId/billings',
      },
      getFileHasBillings: {
        method: 'GET',
        path: '/customs/clerk/:fileId/invoicing',
      },
      addInvoicingLine: {
        method: 'POST',
        path: '/customs/clerk/:fileId/invoicing/:customsClerkBilling',
      },
      updateInvoicingLine: {
        method: 'PATCH',
        path: '/customs/clerk/:fileId/invoicing/update/:customsClerkBilling',
      },
      removeInvoicingLine: {
        method: 'DELETE',
        path: '/customs/clerk/:fileId/invoicing/:customsClerkBilling',
      },
      validateFile: {
        method: 'PATCH',
        path: '/customs/clerk/:fileId/validate/invoicing',
      },
      updateInvoicingStep: {
        method: 'PATCH',
        path: '/customs/clerk/:fileId/update/invoicing',
      },
      getIssueTimes: {
        method: 'GET',
        path: '/customs/clerk/:fileId/issue/time/',
      },
      addIssueTime: {
        method: 'POST',
        path: '/customs/clerk/:fileId/issue/time/',
      },
      updateIssueTime: {
        method: 'PATCH',
        path: '/customs/clerk/:fileId/issue/time/:issueTimeId',
      },
      removeIssueTime: {
        method: 'DELETE',
        path: '/customs/clerk/:fileId/issue/time/:issueTimeId',
      },
      validateFileState: {
        method: 'PATCH',
        path: '/customs/clerk/:companyId/:fileId/validate/:reOpen',
      },
      createControlPDF: {
        method: 'POST',
        path: '/customs/clerk/:fileId/:companyId/createControlPDF/:declarationId',
      },
      getPdfFile: {
        method: 'GET',
        path: '/customs/clerk/:sessionPath/:declarationId/getPdf',
      },
      lock: {
        method: 'POST',
        path: '/customs/clerk/:fileId/lock',
      },
      unlock: {
        method: 'POST',
        path: '/customs/clerk/:fileId/unlock',
      },
      pochette: {
        method: 'POST',
        path: '/customs/clerk/:fileId/:companyId/pochette/:declarationId',
      },
    },
  },
  admin: {
    permissions: {
      getAll: {
        method: 'GET',
        path: '/admin/permissions/:operatorId',
      },
    },
    reports: {
      reportBug: {
        method: 'POST',
        path: '/admin/report/',
      },
    },
  },
  payment: {
    createSubscription: {
      method: 'POST',
      path: '/payment/:companyId/createSubscription',
    },
    createToken: {
      method: 'GET',
      path: '/payment/:companyId/card/create',
    },
    updateToken: {
      method: 'GET',
      path: '/payment/:companyId/card/update',
    },
    unsubscribe: {
      method: 'GET',
      path: '/payment/:companyId/:serviceId/unsubscribe',
    },
    cancelUnsubscribe: {
      method: 'GET',
      path: '/payment/:companyId/:serviceId/cancelUnsubscribe',
    },
  },
  contact: {
    method: 'POST',
    path: '/contact',
  },
  internalTools: {
    ssr: {
      ticketSsr: {
        method: 'POST',
        path: '/internalTools/ssr/process',
      },
      sendResults: {
        method: 'POST',
        path: '/internalTools/ssr/sendResults',
      },
      getFormSchemas: {
        method: 'GET',
        path: '/internalTools/ssr/formSchemas',
      },
    },
    commercial: {
      sendForm: {
        method: 'POST',
        path: '/internalTools/commercial/generateOffer',
      },
      packages: {
        method: 'GET',
        path: '/internalTools/commercial/packages',
      },
      basic: {
        method: 'GET',
        path: '/internalTools/commercial/services/basic',
      },
      getFormSchemas: {
        method: 'GET',
        path: '/internalTools/ssr/formSchemas',
      },
    },
  },
  operator: {
    allOperator: {
      method: 'GET',
      path: '/operator/',
    },
    oneOperator: {
      method: 'GET',
      path: '/operator/:operatorId/permissions',
    },
    updateOperatorRights: {
      method: 'PATCH',
      path: '/operator/:operatorId',
    },
    addOneOperatorRoleApi: {
      method: 'POST',
      path: '/operator/:operatorId/role',
    },
  },
  news: {
    create: {
      method: 'POST',
      path: '/news/create',
    },
    getOne: {
      method: 'GET',
      path: '/news/:newsId',
    },
    getAll: {
      method: 'GET',
      path: '/news/',
    },
    getAllReadNews: {
      method: 'GET',
      path: '/news/ReadNews/:userId',
    },
    readNews: {
      method: 'GET',
      path: '/news/readNews/:newsId/:userId/:service',
    },
    notOpenedNews: {
      method: 'GET',
      path: '/news/notOpenedNews/:userId/:service',
    },
    isReadByUser: {
      method: 'GET',
      path: '/news/isRead/:newsId/:userId',
    },
    remove: {
      method: 'DELETE',
      path: '/news/:newsId',
    },
    removeAll: {
      method: 'DELETE',
      path: '/news/',
    },
    update: {
      method: 'PATCH',
      path: '/news/:newsId',
    },
  },
};
