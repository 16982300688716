import * as auth from './auth/middlewares';
import * as data from './data/middlewares';
import * as customsDeclaration from './customs/declaration/middlewares';
import * as intrastat from './intrastat/middlewares';
import * as chat from './chat/middlewares';
import * as customsClerk from './customs/clerk/middlewares';
import * as customsStatistiques from './customs/statistiques/middlewares';
import * as fiscalDeclaration from './fiscal/declaration/middlewares';
import * as fiscalOss from './fiscal/oss/middlewares';
import * as fiscalVat from './fiscal/vat/middlewares';
import * as quickfixes from './fiscal/quickFixes/middlewares';
import * as quickfixesToken from './fiscal/quickFixes/token/middlewares';
import * as collectionbox from './fiscal/collectionBox/middlewares';
import * as socket from './socket/middlewares';
import * as user from './user/middlewares';
import * as service from './service/middlewares';
import * as admin from './admin/middlewares';
import * as company from './company/middlewares';
import * as contact from './company/contact/middlewares';
import * as companyUser from './company/user/middlewares';
import * as companyDriver from './company/driver/middlewares';
import * as companyKeyInfo from './company/keyinfo/middlewares';
import * as companyClerksPricing from './company/clerksPricing/middlewares';
import * as companyIntrastatSetting from './company/settings/intrastat/middlewares';
import * as companyTaxflowSetting from './company/settings/taxflow/middlewares';
import * as companyCollectionBoxSetting from './company/settings/collectionbox/middlewares';
import * as companyInvoice from './company/invoice/middlewares';
import * as vat from './company/vat/middlewares';
import * as addressServices from './company/address/middlewares';
import * as companyPaymentServices from './company/payment/middlewares';
import * as companyByToken from './company/token/middlewares';
import * as agency from './agencies/middlewares';
import * as payment from './payment/middlewares';
import * as contactRequest from './contactRequest/middlewares';
import * as ssrRequest from './internalTools/ssrRequest/middlewares';
import * as commercial from './internalTools/commercial/middlewares';
import * as notifications from './notifications/middlewares';
import * as operators from './operators/middlewares';
import * as news from './news/middlewares';
import * as status from './status/middlewares';
import * as configurations from './company/configurations/middlewares';

export function apiMiddlewares({ api }) {
  return [
    auth.apiMiddleware({ api }),
    customsDeclaration.apiMiddleware({ api }),
    intrastat.apiMiddleware({ api }),
    chat.apiMiddleware({ api }),
    customsClerk.apiMiddleware({ api }),
    customsStatistiques.apiMiddleware({ api }),
    fiscalDeclaration.apiMiddleware({ api }),
    fiscalOss.apiMiddleware({api}),
    fiscalVat.apiMiddleware({api}),
    collectionbox.apiMiddleware({api}),
    quickfixes.apiMiddleware({ api }),
    quickfixesToken.apiMiddleware({ api }),
    data.apiMiddleware({ api }),
    socket.apiMiddleware({ api }),
    user.apiMiddleware({ api }),
    service.apiMiddleware({ api }),
    admin.apiMiddleware({ api }),
    company.apiMiddleware({ api }),
    contact.apiMiddleware({ api }),
    companyUser.apiMiddleware({ api }),
    companyDriver.apiMiddleware({ api }),
    companyKeyInfo.apiMiddleware({ api }),
    companyClerksPricing.apiMiddleware({ api }),
    companyIntrastatSetting.apiMiddleware({api}),
    companyTaxflowSetting.apiMiddleware({api}),
    companyCollectionBoxSetting.apiMiddleware({api}),
    companyInvoice.apiMiddleware({ api }),
    vat.apiMiddleware({ api }),
    addressServices.apiMiddleware({ api }),
    companyByToken.apiMiddleware({ api }),
    agency.apiMiddleware({ api }),
    payment.apiMiddleware({ api }),
    companyPaymentServices.apiMiddleware({ api }),
    contactRequest.apiMiddleware({ api }),
    ssrRequest.apiMiddleware({ api }),
    commercial.apiMiddleware({ api }),
    notifications.apiMiddleware({ api }),
    operators.apiMiddleware({ api }),
    news.apiMiddleware({ api }),
    status.apiMiddleware({ api }),
    configurations.apiMiddleware({ api }),
  ];
}
