import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Header, Table, Container } from 'semantic-ui-react';

import Button from 'components/Button';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import { actions, selectors } from 'store';
import NewDeclaration from 'views/Fiscal/Vat/Operator/New';
import DownloadFilesModal from 'views/Customs/Declaration/Service/DownloadFilesModal/downloadFilesModal';
import EmptyTable from 'components/EmptyTable';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths } from 'routes/fiscal/repository';
import Pagination from 'components/Pagination';
import ClickableText from 'components/ClickableText';
import { FList, Followers, State, HeaderSub, Period, Spacer, header_sub_css, DropDownVat } from './styles';

const Index = ({
  dispatch,
  history,
  error,
  match,
  companySelected,
  user,
  company,
  companyLoading,
  declarationsLoading,
  declarations,
  euCountries,
  euCountriesLoading,
  obligationsloading,
  obligations,
}) => {
  const userAdminOrOp = user.operator || user.admin;
  const companyId = userAdminOrOp ? match.params.companyId : match.params.companyId;
  const LIMIT = 50;
  const PAGE = 1

  const [modalStatus, setmodalStatus]: any = useState(false);
  const [selectedCountry, setSelectedCountry]: any = useState(null);
  const [monthsList, setMonthList]: any = useState([])
  const [isoList, setIsoList]: any = useState([])
  const [downloadFilesModal, setdownloadFilesModal]: any = useState(false);
  const [column, setcolumn]: any = useState(null);
  const [direction, setdirection]: any = useState(null);
  const [data, setData]: any = useState([]);
  const [count, setCount]: any = useState(null);
  //const [currentData, setCurrentMonth]: any = useState([]);
  const [session, setSession]: any = useState({
    period: sessionStorage.getItem(`vat_${companyId}`) ? JSON.parse(sessionStorage.getItem(`vat_${companyId}`)).period : '', 
    iso: sessionStorage.getItem(`vat_${companyId}`) ? JSON.parse(sessionStorage.getItem(`vat_${companyId}`)).iso : ''
  })
  const [resetSearch, setresetSearch]: any = useState(false);

 
  useEffect(() => {
    dispatch(actions.company.getOne({
      params: {
        companyId: companyId
      }
    }));
    dispatch(
      actions.fiscalVat.getAllVatDeclarations({
        query: {
          limit: LIMIT,
          page: PAGE,
          companyId: companyId
        },
      })
    );
    dispatch(actions.data.getEuCountries());
    dispatch(actions.socket.subscribeVatDeclarations(companyId));
  
    return () => {

      dispatch(
        actions.fiscalVat.reset(['getVatDeclarations', 'error'])
      );

      dispatch(actions.company.reset(['error','company']));
      dispatch(actions.data.reset(['euCountries', 'error']));
      dispatch(actions.socket.unsubscribeVatDeclarations(companyId));
    }

  }, [dispatch, companyId])

  useEffect(() => {
    if(declarations){
      setData(declarations.rows)
      setCount(declarations.count)
      resetSearch && setresetSearch(false);
    }
  }, [resetSearch, declarations]);

  useEffect(() => {
    if(company && euCountries){
      const country = euCountries.find((e) => e.country_code === company.country)
      setSelectedCountry(country)
    }
  }, [company, euCountries])

  useEffect(() => {
    if(declarations){
      // current period tabpane
      // const current = new Date()
      // const currentRepository = declarations.rows.filter(
      //   (e) => Number(new Date(e.taxPeriod).getMonth()) === Number(current.getMonth()-1) && e.state !== 'cancelled'
      //   )
      //   currentRepository && setCurrentMonth(currentRepository)
  
      // create filter lists
      const activePeriods: any = [], iso: any = [];
      for(const item of declarations.rows){
        const date = new Date(item.taxPeriod)
        const period = `${date.getMonth()}-${date.getFullYear()}`
        const found = activePeriods.some((value) => value === period);
        const isoFound = iso.some((value) => value === item.country);

        if (!found) {
          activePeriods.push(period);
        }
        if(!isoFound){
          iso.push(item.country)
        }
      }
      setMonthList(activePeriods.map((period, index) => {
        return {
          key: index,
          value: period,
          text: `${new Date(period.split('-')[1], period.split('-')[0]).toLocaleDateString('en', {
            year: 'numeric',
            month: 'long',
          })}`
        }
      }))
      setIsoList(iso.map((el, index) => {
        return {
          key: index,
          value: el,
          text: el,
        }}))

      // init filters with stored values
      const session = JSON.parse(sessionStorage.getItem(`vat_${companyId}`))
      session && setSession(prevState => ({
        ...prevState,
        iso: session.iso,
        period: session.period
      })) 
    }
  }, [declarations, companyId]);

  useEffect(() => {
    const createPeriod = (taxPriod) => {
      let date = new Date(taxPriod)
      return `${date.getMonth()}-${date.getFullYear()}`

    }
    // period and country filters
    const result = session && declarations && declarations.rows.filter(
      (value) => 
        (session.period !== '' && session.iso !== '') ?
        createPeriod(value.taxPeriod) === session.period && value.country === session.iso 
        :
        (session.period === '' && session.iso) ?
          value.country === session.iso 
        :
        (session.iso === '' && session.period !== '') ?
          createPeriod(value.taxPeriod) === session.period 
        :
        (session.period === '' && session.iso === '') ?
          value : null
        
      );

      if(result){
        sessionStorage.setItem(`vat_${companyId}`, JSON.stringify(session))
        setData(result)
      }
  }, [declarations, session, companyId])

 
  const extractDocuments = (data) => {
    dispatch(
      actions.customsDeclaration.extractDocuments({
        params: {
          companyId,
          from: data.startDate,
          to: data.endDate,
        },
        data: {
          withDocuments: true,
          docType: 'dau',
          attributes: [
            'id',
            'fortyTwoRegime',
            'createdAt',
            'state',
            'trailerLicensePlate',
            'truckLicensePlate',
            'truckOrigin',
          ],
        },
      })
    );
    setdownloadFilesModal(false);
  };

  // const onSearchResultChange = (newData) => {
  //   setdata(_.orderBy(newData, ['id'], ['desc']));
  // };

  const handleSort = (name) => {
    if(column !== name){
      setcolumn(name);
      setdirection('descending');
      setData(_.sortBy(data, [name]));
      return;

    }
    setData(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };
  
  const handlePageChange = (data) => {
    dispatch(
      actions.customsDeclaration.getAll({
        params: { companyId },
        query: {
          limit: LIMIT,
          page: Math.ceil(data.activePage),
        },
      })
    );
  };

  return (
    <Wrapper width='80%' margin='auto'>
      {userAdminOrOp && (
        <>
          <Divider hidden />
          <Wrapper display='flex'>
            <ButtonBack history={history} />
          </Wrapper>
        </>
      )}
      <Divider hidden />
      <Header size='huge' textAlign='center' style={header_sub_css}>Vat declaration dashboard <HeaderSub>{company && company.name}</HeaderSub></Header>
      <Container textAlign='center'>
       {navigator.language === 'fr-FR' ? (
          <ClickableText
            color='black'
            onClick={() => {
              window.open(
                'https://my-asd.s3-eu-west-1.amazonaws.com/customs/Guide_de_l_utilisateur_-_Déclaration_en_douane.pdf',
                '_blank'
              );
              }}
            >
            Guide d'utilisation
        </ClickableText>
       ) : (
        <ClickableText
          color='black'
          onClick={() => {
            window.open(
              'https://my-asd.s3-eu-west-1.amazonaws.com/customs/User_guide_-_Customs_declaration.pdf',
              '_blank'
            );
            }}
          >
        User guide 
        </ClickableText>
       )}        
      </Container>
      <Divider hidden />
      <Wrapper display='flex' justifyContent='space-between'>
        {!error && (
          <Wrapper display='flex' justifyContent='space-around'>
            <Button
              labelPosition='right'
              position='left'
              icon='plus circle'
              type='button'
              content='New declaration'
              onClick={() => setmodalStatus(true)}
            />
            <DropDownVat
              clearable
              search
              selection
              placeholder='Tax period...'
              options={monthsList}
              defaultValue={session.period || null}
              onChange={(e, {value}) => {
                e && setSession(prevState => ({
                  ...prevState,
                  period: value
                }))
              }}
            />
        </Wrapper>
        )}

      </Wrapper>

      <Divider hidden />
      {company && (
        <NewDeclaration
          open={modalStatus}
          companyName={company.name}
          selectedCountry={selectedCountry}
          handleClose={() => setmodalStatus(false)}
          companyId={companyId}
          match={match}
        />
      )}
      {downloadFilesModal && (
        <DownloadFilesModal
          open={downloadFilesModal}
          handleClose={() => setdownloadFilesModal(false)}
          match={match}
          extractDocuments={extractDocuments}
        />
      )}

      {((
        declarations === null || 
        count === null || 
        data === null || 
        declarationsLoading ||
        euCountriesLoading ||
        obligationsloading ||
        companyLoading
      ) && <Loader content='Loading' />)}
  
        <Wrapper overflow='auto'>
          <p>Number of declarations: {count}</p>
          <Table columns='8' stackable sortable selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'id' ? direction : null}
                  onClick={() => handleSort('id')}
                >
                  Declaration
                </Table.HeaderCell>
                <Table.HeaderCell
                   sorted={column === 'taxPeriod' ? direction : null}
                   onClick={() => handleSort('taxPeriod')}>
                  Tax Period
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'createdAt' ? direction : null}
                  onClick={() => handleSort('createdAt')}
                >
                  Created On
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                  Country
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'periodicity' ? direction : null}
                  onClick={() => handleSort('periodicity')}
                >
                  Periodicity
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'nth' ? direction : null}
                  onClick={() => handleSort('nth')}
                >
                  nth
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'emitter' ? direction : null}
                  onClick={() => handleSort('emitter')}
                >
                  Emitter
                </Table.HeaderCell>
                <Table.HeaderCell
                >
                  Follower
                </Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={9}/>) ||
                (data && data.map((e, i) => {
                  return (
                    <Table.Row key={i}>
                      <Link
                        to={paths.repositoryDetails
                          .replace(':companyId', e.Company_Id)
                          .replace(':declarationId', e.id)}
                        className='table-link'
                      >
                        <Table.Cell>{e.id}</Table.Cell>
                        <Table.Cell>
                        <Period>
                          {new Date(e.taxPeriod).toLocaleDateString('en', {
                            month: 'long',
                          })}
                        <Spacer>
                          {new Date(e.taxPeriod).toLocaleDateString('en', {
                            year: 'numeric',
                          })}
                        </Spacer>
                        </Period>                      
                        </Table.Cell>                     
                        <Table.Cell>
                          {new Date(e.createdAt).toLocaleDateString('en', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}             
                        </Table.Cell>                     
                        <Table.Cell>{e.country}</Table.Cell>
                        <Table.Cell>{e.periodicity && <Period>{e.periodicity}</Period>}</Table.Cell>    
                        <Table.Cell>{e.nth && <Period>{e.nth}</Period>}</Table.Cell>                       
                        <Table.Cell>{e.emitter.fullname}</Table.Cell>
                        <Table.Cell>
                          <Followers>
                          {
                            e.follower && 
                            e.follower.length &&  
                            e.follower.map((user, i) => (
                                  <FList key={i}>{`${user.operator}`}</FList>
                                ))
                          }
                          </Followers>                     
                        </Table.Cell>
                        <Table.Cell>
                          <State defaultValue={e.state === 'cancelled' ? 'red' : e.state === 'processing' ? '#dda80b' : '#00619b'}>{e.state}</State>
                        </Table.Cell>
                      </Link>
                    </Table.Row>
                  );
                }))}
            </Table.Body>
          </Table>
        </Wrapper>
   
      <Divider hidden />
      <Wrapper display='flex' justifyContent='center'>
        <Pagination
          defaultActivePage={1}
          totalPages={count / LIMIT}
          onChange={handlePageChange}
        />
      </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    company: selectors.company.companySelector(state),
    companyLoading: selectors.company.getOneLoadingSelector(state),
    declarations: selectors.fiscalVat.getfiscalVatDeclarationsSelector(state),
    declarationsLoading: selectors.fiscalVat.getfiscalVatDeclarationsLoadingSelector(state),
    euCountries: selectors.data.euCountriesSelector(state),
    euCountriesLoading: selectors.data.euCountriesLoadingSelector(state),
    error: selectors.message.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
